import React from 'react'
import '../PeripheralArteryDisease/Component/ArteryBanner.css'
import '../PeripheralArteryDisease/PeripheralArteryDisease.css'
import '../Aboutus/Aboutus.css'

function Aboutus() {
  return (
    <>
          <div className='about_banner common_banner_sec'>
              <div className='banner_body'>
                  <div className='banner_wrapper'>
                      <div className='banner_wrpr_bg'>
                          <div className='banner_con'>
                              <div className='common_width'>
                                  <div className='banner_con_text'>
                                      <div className='banner_con_text1'>
                                          <h1>About Health  <br/> <span>and Pain Care LLC</span></h1>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className='about_block2'>
            <div className='common_width'>
                  <div className='about_block2_subwrapper'>
                      <div className='roundimage_imgmainwrapper'>
                          <div className='roundimage_imgsubwrapper'>
                              <div className='roundimage_imgwrapper'>
                                  <img src={`${process.env.REACT_APP_IMG_URL}block6_image1.jpg`} />
                              </div>
                          </div>
                      </div>
                <div className='textwrapper'>
                      <h2>GETTING YOUR HEALTH BACK IS <br/>
                          EASIER THAN YOU THINK</h2>
                      <p>If you have been struggling with back pain or other chronic conditions our clinic is the place you need to visit! When people are struggling with chronic pain, often they forget how life used to be when they were healthy. Some people tend to succumb to this condition, ignoring it for years and letting it control their lives. But here at our center we focus on true healing and not just the management of our patient’s continued debilitating symptoms. We have the expertise to help you get well!</p>
                      <p>Health and Pain Care is a multidisciplinary clinic that includes chiropractic, preventative disease screening and nurse practitioner services. Many chronic conditions have multiple complicating factors that lead to worsening or never improving pain and dysfunction. Experience has taught us that addressing the causes more than symptoms gives our patients greater satisfaction and results. We are dedicated to using the latest in early disease detection and up to date methods giving our patients the best in health care services possible.</p>
                </div>
                </div>
            </div>
          </div>
                  <div className="about_block3 right_image_andtext">
                      <div className="textandimagewrapper">
                          {/* <img src="https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/home_page_bg2.jpg" className='abimage' /> */}
                          <div className="common_width">
                              <div className="textandimagewrapper_padding">
                                  <div className="textwrapper">
                                      <p>
                                  At our clinic we provide advanced services in pain and inflammation healing and care management.  We utilize electric cell signaling Sanexas therapies improving nerve health combined with key nutrients that help stabilize and regenerate nerves alleviating pain and improving the overall wellness of our patients.  Combined with this we focus on mental health services with expertise in house for the overall
                                  wellness of our patients.

                                      </p>
                                      <p>
                                  At our center the chiropractic services that we provide are based on the best research and latest science and techniques available. Dr. Smith is a certified
                                  chiropractic neurologist with expertise and experience in all things chiropractic, gut health and food sensitivities. Besides all of the expected services we provide joint manipulation and deep muscle work and are known for our incredible results. These modalities provide a diverse and effective plan of care opportunity for patients
                                  addressing a variety of alignment and pain issues.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
    </>
  )
}

export default Aboutus
