import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../store";


// API call for users-list
export const getTestimonialList = createAsyncThunk("testimonial/list", async (reqbody) => {

    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    // console.log("reqbody==>", reqbody);
    if (reqbody == null && reqbody == undefined) {
        reqbody = {
            "source": "testimonial_views",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "type": "desc",
                "field": "createdon_datetime"
            },
            'token': accessToken,
            'email': userMail,
            "searchcondition": {},
            "relation": "Backend"
        }
    }

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/testimoniallistingusers", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const updateStatusTesTimonial = createAsyncThunk("userTestimonial/statuschange", async (reqbody) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/updateStatusTestimonial", requestOptions);
    const respdata = await response.json();
    return respdata;

})


// ///////////////////delete multiple/////////////////
// export const multidelete = createAsyncThunk(
//     "multideleteuser",
//     async (deleteMany) => {
//         console.log("delete----------------------------------", deleteMany);
//         let req_body = {};
//         req_body._ids = deleteMany;
//         req_body._ids = deleteMany.onlyids;
//         const requestOptions = {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(req_body),
//         };
//         const response = await fetch(
//             `${process.env.REACT_APP_API_URL}api3/multideleteTestimonial`,
//             requestOptions
//         );
//         const res = await response.json();
//         console.log("res delete----------------------------------", res);

//         return res;
//     }
// );



// //////Api call for  multi-delete///////

export const getTestimonialmultidelete = createAsyncThunk("userTestimonial/multidelete", async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    let req_body = {};
    req_body = { _ids: reqbody, source: "testimonial_schemas", email: userMail, token: accessToken };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideletetestimonial", requestOptions);
    const respdata = await response.json();
    return respdata;
});



// API call for multi-edit

export const getTestimonialmultiedit = createAsyncThunk("userTestimonial/multiedit", async (reqbody) => {



    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multiedittestimonial", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const getTestimonialSearchList = createAsyncThunk("testimonial/search", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/testimoniallistingusers", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/testimoniallistinguserscount", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
});





const tesTimonial = createSlice({
    name: "tesTimonial",
    initialState: {
        submitting: false,
        tesTimonialCount: null,
        loading: false,
        submitted: false,
        message: null,
        tesTimonialusersList: [],   // For backend data
        tesTimonialList: [],        // For Frontend data
        tesTimonialDetail: {        // For Frontend Detail Page
            testimonialArr: [],
            singleTestimonial: null
        },
        loadMore: false,
        viewLess: false,
        successmsg: "",
        success: false,
        currentEditUserData: null,
        statusUpdateFlage: null,
        maltiDeleteFlage: null,
        editDataforTestimonial: {}
    },

    reducers: {

        setSuccessAfterSubmit(state, action) {
            state.success = false;
        },
        currentEditUserData(state, action) {
            state.currentEditUserData = action.payload
        },
        setMessagefterSubmit(state, action) {
            state.messageflag = false;
            state.message = null;
        },

        resetSuccess(state, action) {
            state.success = false;
        },

        setEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
                state.editDataforTestimonial = action.payload;
            }
        },
        clearEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            state.editDataforTestimonial = null;
        },
        clearTestimonialList(state, action) {
            if (action.payload) {
                if (action.payload == 'Frontend List') {
                    state.tesTimonialList = [];
                    console.log("clearTestimonialList ---->", state.tesTimonialList);
                }
                if (action.payload == 'Backend List') state.tesTimonialusersList = [];
            }
        },
        clearTestimonialDetails(state, action) {
            if (action.payload && (action.payload = "Frontend Details")) {
                state.tesTimonialDetail.testimonialArr = [];
                state.tesTimonialDetail.singleTestimonial = null;
            }
        },

        clearstatusUpdateFlage(state, action) {
            state.statusUpdateFlage = null;
        },

        clearmaltiDeleteFlage(state, action) {
            state.maltiDeleteFlage = null;
        },


    },

    extraReducers: {


        

        //-----------------------TESTIMONIAL LISTING------------------//

        [getTestimonialList.pending]: (state, action) => {
            state.loading = true;
        },

        [getTestimonialList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") {
                if (action.payload.reqbody.relation === "Frontend") state.tesTimonialList = action.payload.results.res;
                else if (action.payload.reqbody.relation === "Frontend Details") {
                    state.tesTimonialDetail.testimonialArr = action.payload.results.res;
                    state.tesTimonialDetail.singleTestimonial = action.payload.singleData[0] ? action.payload.singleData[0] : null;
                } else if (action.payload.reqbody.relation == 'Backend') state.tesTimonialusersList = action.payload.results.res;
            }
        },




        [getTestimonialList.rejected]: (state, action) => {
            state.loading = false;
        },


        //------------Status Update------------//


        [updateStatusTesTimonial.pending]: (state, action) => {
            state.loading = true;
            state.statusUpdateFlage = true;
        },


        [updateStatusTesTimonial.fulfilled]: (state, action) => {

            if (action.payload.status == 'success') {
                state.loading = false;
                state.submitting = false;
                state.statusUpdateFlage = false;
            }
        },

        [updateStatusTesTimonial.rejected]: (state, action) => {
            state.loading = false;
            state.statusUpdateFlage = false;
        },




        //-------------------- MULTI EDIT TESTIMONIAL-----------------------------//


        [getTestimonialmultiedit.pending]: (state, action) => {

            state.loading = true;
        },
        [getTestimonialmultiedit.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Updated Successfully';
                state.success = true;
            }

        },
        [getTestimonialmultiedit.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
        },


        //-------------------------- Delete Multiple Testimonial--------------------------//


        [getTestimonialmultidelete.pending]: (state, action) => {
            state.loading = true;
            state.maltiDeleteFlage = true;
        },
        [getTestimonialmultidelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Deleted Successfully';
                state.maltiDeleteFlage = false;


            }

        },
        [getTestimonialmultidelete.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
            state.maltiDeleteFlage = false;

        },


        // -----------------------Testimonial Search---------------------//


        [getTestimonialSearchList.pending]: (state, action) => {
            state.loading = true;
            state.tesTimonialusersList = null;
            state.message = null;
        },
        [getTestimonialSearchList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
                state.tesTimonialusersList = action.payload.respData.results.res;
                state.tesTimonialCount = action.payload.respCount.count;
                state.message = action.payload.respData.status;
            }
        },

        [getTestimonialSearchList.rejected]: (state, action) => {
            state.loading = false;
        },


    }


})

export default tesTimonial.reducer;
export const { setSuccessAfterSubmit, currentEditUserData, setMessagefterSubmit, resetSuccess, setEditData, clearEditData, clearstatusUpdateFlage, clearmaltiDeleteFlage, clearTestimonialList, clearTestimonialDetails } = tesTimonial.actions;
