import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataBody, generateBody } from "../../../common/ReuseComponents/reqBody";
import store from "../../../store";

//////////////////////////// For user list ////////////////////////
export const getdataforVideolist = createAsyncThunk(
  "gallery/video",
  async (reqBody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    if (reqBody == null && reqBody == undefined) {
      reqBody = {
        "source": "video_views",
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "type": "desc",
          "field": "createdon_datetime"
        },
        'token': accessToken,
        'email': userMail,
        "relation": "Backend",
        "searchcondition": {}
      }
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch( `${process.env.REACT_APP_API_URL}api1/videolisting`, requestOptions );
    console.log("Next 222 step", response);
    const res = await response.json();

    return res;
  }
);


export const getVideoSeachList = createAsyncThunk("gallery/video/list", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/videolisting", requestOptions);
  const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/videolistingcount", requestOptions);
  const respData = await responseData.json();
  const respCount = await responseCount.json();
  return { respData, respCount };
});

export const addUpdateVideo = createAsyncThunk("gallery/video/add", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/addupdatevideo", requestOptions);
  const respData = await responseData.json();
  return respData;
});

export const videoStatuschange = createAsyncThunk("gallery/video/add", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/videochangestatus", requestOptions);
  const respData = await responseData.json();
  return respData;
});

//---------------API CALL FOR MULTI-DELETE----------------------//

export const getVideoGallerymultidelete = createAsyncThunk("videoGallery/multidelete", async (reqbody) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
  let req_body = {};
  req_body = { _ids: reqbody , source: "videos", email: userMail, token: accessToken };
  // req_body=dataBody(req_body)
  console.log("req_body_delete==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideletevideogallery", requestOptions);
  const respdata = await response.json();
  return respdata;
});

//---------------API CALL FOR MULTI-EDIT----------------------//

export const getVideoGallerymultiedit = createAsyncThunk("videoGallery/multiedit", async (reqbody) => {



  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "api1/multieditvideogallery", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

//--------------------------------------------------------------------//





const videogallerySlice = createSlice({
  name: 'videogallerySlice',
  initialState: {
    loading: false,
    message: null,
    videoGalleryData:[],
    loadMore: false,
    viewLess: false,
    videolist: null,
    videoListCount: null,
    statusChangeFlag:null,
    editDataForVideo:{},
    successmsg: "",
    success: false,
    submitted: false,
    submitting: false,
    multiDeleteFlage:null,
    multiEditFlage:null,
    videoID:null
  },
  reducers: {

    setVideoID(state, action) {
      if (action?.payload ) state.videoID = action.payload;
    },

    setEditDataForVideo(state, action) {
      console.log("state from seteditdata==>", state, action);
      if (action.payload !== undefined && action.payload != null) {
        state.editDataForVideo = action.payload;
      }
    },
    clearEditDataForVideo(state, action) {
      console.log("state from seteditdata==>", state, action);
      state.editDataForVideo = null;
    },
    clearVideoDetails(state, action) {
      if (action?.payload && action.payload == 'Frontend Details') state.videoGalleryData = [];
    },

    clearVideoGalList(state, action){
      state.videoGalleryData = [];
    },
    clearVideoGalList(state, action) {
      // console.log('Action of clearVideoGalList ===>', action);
      if (action.payload && (action.payload !== undefined)) {
        if (action.payload == 'Frontend List') state.videoGalleryData = [];
        if (action.payload == 'Backend List') state.videoGalleryData = [];
      }
    },
    clearStatusChangeFlag(state, action){
      state.statusChangeFlag = null;
    },
    clearMultiDeleteFlage(state, action){
      state.multiDeleteFlage = null;
    },
    clearMultiEditFlage(state, action){
      state.multiEditFlage = null;
    }
  },
  extraReducers: {
    ////////////////////////////////userlistasync for dashboard////////////////
    [getdataforVideolist.pending]: (state, action) => {
      state.loading = true;
      state.videolist =null
    },
    [getdataforVideolist.fulfilled]: (state, action) => {
      console.log("action", action);

      state.loading = false;
      if (action.payload.status === "success") {
        if (action.payload.reqbody.relation === "Frontend") state.videoGalleryData = action.payload.results.res;
        else if (action.payload.reqbody.relation == 'Backend') state.videolist = action.payload.results.res;
        state.submitting = false;
        state.message = action.payload.message;
      }



    },
    [getdataforVideolist.rejected]: (state, action) => {
      state.loading = false;
    },
    ////////////////////////////////add video////////////////
    [addUpdateVideo.pending]: (state, action) => {
      state.loading = true;
    },
    [addUpdateVideo.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        // state.videoGalleryData = action.payload.results.res;
        state.loading = false;
        state.submitting = false;

      }
    },
    [addUpdateVideo.rejected]: (state, action) => {
      state.loading = false;
    },
    //////////////////////////////////////////////////////////////////////////////////////////////
    [videoStatuschange.pending]: (state, action) => {
      state.loading = true;
      state.statusChangeFlag=true;

    },
    [videoStatuschange.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        // state.videoGalleryData = action.payload.results.res;
        state.loading = false;
        state.submitting = false;
        state.statusChangeFlag=false;

      }
    },
    [videoStatuschange.rejected]: (state, action) => {
      state.loading = false;
      state.statusChangeFlag=false;

    },



      //--------------------FOR MULTI-DELETE IMAGE GALLERY----------------------------//


      [getVideoGallerymultidelete.pending]: (state, action) => {
        state.loading = true;
        state.multiDeleteFlage =true
  
      },
      [getVideoGallerymultidelete.fulfilled]: (state, action) => {
        console.log("action", action);
        if (action.payload.status === "success") {
          state.loading = false;
          state.successmsg = 'Deleted Successfully';
          state.multiDeleteFlage =false;
          state.submitting = false;
  
  
  
        }
      },
      [getVideoGallerymultidelete.rejected]: (state, action) => {
        state.loading = false;
        state.successmsg = 'Something went wrong';
        state.multiDeleteFlage =false;
  
      },
  
  
      //---------------------MULTI-EDIT IMAGE GALLERY------------------------//
  
  
      [getVideoGallerymultiedit.pending]: (state, action) => {
        state.loading = true;
        state.multiEditFlage = true;
  
      },
      [getVideoGallerymultiedit.fulfilled]: (state, action) => {
        console.log("action", action);
        if (action.payload.status === "success") {
          state.loading = false;
          state.successmsg = 'Updated Successfully';
          state.multiEditFlage =false;
          state.submitting = false;
  
  
        }
      },
      [getVideoGallerymultiedit.rejected]: (state, action) => {
        state.loading = false;
        state.multiEditFlage =false;
        state.successmsg = 'Something went wrong';
  
      },
  
  
      //------------------------------------------------//
  
  
  

    //////////////////////////////////////////////////////////////////////////////////////////////
    [getVideoSeachList.rejected]: (state, action) => {
      state.loading = false;
    },
    [getVideoSeachList.pending]: (state, action) => {
      state.loading = true;
      state.videolist = null;
      state.message = null;
    },
    [getVideoSeachList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
        state.videolist = action.payload.respData.results.res;
        state.videoListCount = action.payload.respCount.count;
        state.message = action.payload.respData.status;
      }
    },
  }
})

export const {setEditDataForVideo,setVideoID,clearEditDataForVideo,clearVideoGalList,clearStatusChangeFlag,clearMultiDeleteFlage,clearMultiEditFlage,clearVideoDetails} = videogallerySlice.actions;

export default videogallerySlice.reducer;