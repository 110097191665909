import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//////////////////////////// For Event list ////////////////////////
export const getdataforEventlist = createAsyncThunk(
    "getdataforEventlist",
    async (reqBody) => {
        if (reqBody == null && reqBody == undefined) {
            reqBody = {
                "source": "",
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "type": "asc",
                    "field": "name"
                },
                "searchcondition": {}
            }
        }
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody),
        };
        const response = await fetch(`${process.env.REACT_APP_API_URL}calendarapi/calendar-event-list`, requestOptions);
        console.log("Next 222 step", response)
        const res = await response.json();
        return res;
    }
);


export const getEventSeachList = createAsyncThunk("getEventSeachList", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "calendarapi/calendar-event-list", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "api/", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
});

// ---------------------------------------------For Updating an Event--------------------------------------------- //
export const addUpdateEvent = createAsyncThunk("addUpdateEvent", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api/", requestOptions);
    const respData = await responseData.json();
    return respData;
});

// ----------------------------------------For Changing Status of an Event---------------------------------------- //
export const statusChangeEvent = createAsyncThunk("statusChangeEvent", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api/", requestOptions);
    const respData = await responseData.json();
    return respData;
});

// -------------------------------------------API CALL FOR MULTI-DELETE------------------------------------------- //
export const eventMultidelete = createAsyncThunk("eventMultidelete", async (reqbody) => {
    let req_body = {};
    req_body = { _ids: reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/", requestOptions);
    const respdata = await response.json();
    return respdata;
});

// --------------------------------------------API CALL FOR MULTI-EDIT-------------------------------------------- //
export const eventMultiedit = createAsyncThunk("eventMultiedit", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




// ----------------------------------------------Image Gallery Slice---------------------------------------------- //
const calenderSlice = createSlice({
    name: 'calenderSlice',
    initialState: {
        loading: false,
        message: null,
        eventData: null,
        loadMore: false,
        enentListCount: null,
        statusChangeFlag: null,
        editDataForEvent: {},
        successmsg: "",
        success: false,
        submitted: false,
        submitting: false,
        multiDeleteFlage: null,
        multiEditFlage: null,
    },


    reducers: {
        setEditDataForEvent(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
                state.editDataForEvent = action.payload;
            }
        },
        clearEditDataForEvent(state, action) {
            console.log("state from seteditdata==>", state, action);
            state.editDataForEvent = null;
        },
        clearStatusChangeFlag(state, action) {
            state.statusChangeFlag = null;
        },
        clearMultiDeleteFlage(state, action) {
            state.multiDeleteFlage = null;
        },
        clearMultiEditFlage(state, action) {
            state.multiEditFlage = null;
        },
        setSuccessAfterSubmit(state, action) {
            state.success = false;
        },
    },
    extraReducers: {
        ////////////////////////////////Banner List////////////////
        [getdataforEventlist.pending]: (state, action) => {
            state.loading = true;
        },
        [getdataforEventlist.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {

                state.eventData =action.payload.results.res;
                state.loading = false;
      }
        },
        [getdataforEventlist.rejected]: (state, action) => {
            state.loading = false;
        },

        ////////////////////////////////add Banner////////////////
        [addUpdateEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [addUpdateEvent.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.submitting = false;

            }
        },
        [addUpdateEvent.rejected]: (state, action) => {
            state.loading = false;
        },
        ///////////////////////////////////////////////////////////////////////////////////////
        [statusChangeEvent.pending]: (state, action) => {
            state.loading = true;
            state.statusChangeFlag = true;
        },
        [statusChangeEvent.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.submitting = false;
                state.statusChangeFlag = false;

            }
        },
        [statusChangeEvent.rejected]: (state, action) => {
            state.loading = false;
            state.statusChangeFlag = false;
        },


        //--------------------FOR MULTI-DELETE Banner---------------------------//


        [eventMultidelete.pending]: (state, action) => {
            state.loading = true;
            state.multiDeleteFlage = true

        },
        [eventMultidelete.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Deleted Successfully';
                state.multiDeleteFlage = false;
                state.submitting = false;



            }
        },
        [eventMultidelete.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
            state.multiDeleteFlage = false;

        },


        //---------------------MULTI-EDIT Banner------------------------//


        [eventMultiedit.pending]: (state, action) => {
            state.loading = true;
            state.multiEditFlage = true;

        },
        [eventMultiedit.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Updated Successfully';
                state.multiEditFlage = false;
                state.submitting = false;


            }
        },
        [eventMultiedit.rejected]: (state, action) => {
            state.loading = false;
            state.multiEditFlage = false;
            state.successmsg = 'Something went wrong';

        },


        //------------------------------------------------//





        //////////////////////////////////////////////////////////////////////////////////////////////
        [getEventSeachList.pending]: (state, action) => {
            state.loading = true;
            state.eventData = null;
            state.message = null;
        },
        [getEventSeachList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
                state.eventData = action.payload.respData.results.res;
                state.enentListCount = action.payload.respCount.count;
                state.message = action.payload.respData.status;
            }
        },
        [getEventSeachList.rejected]: (state, action) => {
            state.loading = false;

        },
    }
})

export const { setEditDataForEvent, clearEditDataForEvent, clearStatusChangeFlag, clearMultiDeleteFlage, clearMultiEditFlage,setSuccessAfterSubmit } = calenderSlice.actions;

export default calenderSlice.reducer;