import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultBodyBackend } from "../../../common/ReuseComponents/reqBody";
import store from "../../../store";

//////////////////////////// For user list ////////////////////////
export const getdataforImagelist = createAsyncThunk(
  "getdataforImagelist",
  async (reqBody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    if (reqBody == null && reqBody == undefined) {
      reqBody = {
        "source": "image_views",
        "condition": {
          "limit": 10,
          "skip": 0
        },
        "sort": {
          "type": "desc",
          "field": "createdon_datetime"
        },
        'token': accessToken,
        'email': userMail,
        "searchcondition": {}
      }
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}api1/imagelisting`, requestOptions);
    console.log("Next 222 step", response)
    const res = await response.json();
    return res;
  }
);
// export const getdataforImagelist = createAsyncThunk(
//   "getdataforImagelist",
//   async (reqBody) => {
//     console.log("reqBody ===>", reqBody);
//     if (reqBody == null && reqBody == undefined) reqBody = defaultBodyBackend({source: "image_views"});

//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(reqBody),
//     };
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}api1/imagelisting`,
//       requestOptions
//     );
//     console.log("Next 222 step", response);
//     const res = await response.json();
//     console.log("Next 222333 step", res);

//     return res;
//   }
// );


export const getImageSeachList = createAsyncThunk("image/search/list", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/imagelisting", requestOptions);
  const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/imagelistingcount", requestOptions);
  const respData = await responseData.json();
  const respCount = await responseCount.json();
  return { respData, respCount };
});

// ---------------------------------------------For Updating an image--------------------------------------------- //
export const addUpdateImage = createAsyncThunk("gallery/image/add", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/addupdateimage", requestOptions);
  const respData = await responseData.json();
  return respData;
});

// ----------------------------------------For Changing Status of an image---------------------------------------- //
export const imageStausUpdate = createAsyncThunk("gallery/image/status", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/imagechangestatus", requestOptions);
  const respData = await responseData.json();
  return respData;
});

// -------------------------------------------API CALL FOR MULTI-DELETE------------------------------------------- //
export const getImageGallerymultidelete = createAsyncThunk("imageGallery/multidelete", async (reqbody) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
  let req_body = {};
  req_body = { _ids: reqbody, source: "images", email: userMail, token: accessToken };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideleteimagegallery", requestOptions);
  const respdata = await response.json();
  return respdata;
});

// --------------------------------------------API CALL FOR MULTI-EDIT-------------------------------------------- //
export const getImageGallerymultiedit = createAsyncThunk("imageGallery/multiedit", async (reqbody) => {
  let req_body = {};
  req_body = { ...reqbody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "api1/multieditimagegallery", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});




// ----------------------------------------------Image Gallery Slice---------------------------------------------- //
const imagegallerySlice = createSlice({
  name: 'imagegallerySlice',
  initialState: {
    loading: false,
    message: null,
    imageGalleryData: null,
    imgArr: [],
    imgGalDetail: {        // For Frontend Detail Page
      imgArr: [],
      singleImgCard: null
    },
    loadMore: false,
    viewLess: false,
    // imagelist: null,
    imageListCount: null,
    statusChangeFlag: null,
    editDataForImage: {},
    successmsg: "",
    success: false,
    submitted: false,
    submitting: false,
    multiDeleteFlage: null,
    multiEditFlage: null,
  },


  reducers: {
    setEditDataForImage(state, action) {
      console.log("state from seteditdata==>", state, action);
      if (action.payload !== undefined && action.payload != null) {
        state.editDataForImage = action.payload;
      }
    },
    clearEditDataForImage(state, action) {
      console.log("state from seteditdata==>", state, action);
      state.editDataForImage = null;
    },
    clearImageGalList(state, action) {
      // console.log('Action of clearImageGalList ===>', action);
      if (action.payload && (action.payload !== undefined)) {
        // if (action.payload == 'Office Tour') state.imgGalOfficeTure = [];
        // else state.imgGalBeforeAfter = [];

        state.imgArr=[];
      }
    },
    clearImgGalDetail(state, action) {
      if (action.payload && (action.payload = "Frontend Details")) {
        state.imgGalDetail.imgArr = [];
        state.imgGalDetail.singleImgCard = null;
      }
    },


    clearStatusChangeFlag(state, action) {
      state.statusChangeFlag = null;
    },
    clearMultiDeleteFlage(state, action) {
      state.multiDeleteFlage = null;
    },
    clearMultiEditFlage(state, action) {
      state.multiEditFlage = null;
    }
  },
  extraReducers: {
    ////////////////////////////////userlistasync for dashboard////////////////
    [getdataforImagelist.pending]: (state, action) => {
      state.loading = true;
    },
    [getdataforImagelist.fulfilled]: (state, action) => {
      console.log("action", action);
      // if (action.payload.status === "success") {
      //   if (action.payload.reqbody.condition.skip == 0) {
      //     console.log('Inital Page Load');
      //     if (action.payload.results.res.length < 8) {
      //       state.loadMore = false;
      //       state.viewLess = false;
      //     } else {
      //       state.loadMore = true;
      //       state.viewLess = false;
      //     }
      //   } else {
      //     if (action.payload.results.res.length < 4) {
      //       console.log('Load More Button clicked');
      //       state.loadMore = false;
      //       if (action.payload.results.res.length == 0) state.viewLess = false;
      //       else state.viewLess = true;
      //     } else {
      //       state.loadMore = true;
      //       state.viewLess = false;
      //     }
      //   }


      //   if (action.payload.reqbody.relation == 'Frontend') state.imgArr = [...state.imgArr, ...action.payload.results.res];
      //   else state.imageGalleryData = action.payload.results.res;
      //   state.loading = false;
      //   state.submitting = false;

      // }



      if (action.payload.status === "success") {
        if (action.payload.reqbody.relation === "Frontend") {
          state.imgArr = action.payload.results.res;
        } else if (action.payload.reqbody.relation === "Frontend Details") {
          // console.log("action.payload", action.payload);
          state.imgGalDetail.imgArr = action.payload.results.res;
          state.imgGalDetail.singleImgCard = action.payload.singleData[0] ? action.payload.singleData[0] : null;
        } else state.imageGalleryData = action.payload.results.res;
        state.loading = false;
        state.submitting = false;
      }




    },
    [getdataforImagelist.rejected]: (state, action) => {
      state.loading = false;
    },

    ////////////////////////////////add video////////////////
    [addUpdateImage.pending]: (state, action) => {
      state.loading = true;
    },
    [addUpdateImage.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        // state.videoGalleryData = action.payload.results.res;
        state.loading = false;
        state.submitting = false;

      }
    },
    [addUpdateImage.rejected]: (state, action) => {
      state.loading = false;
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    [imageStausUpdate.pending]: (state, action) => {
      state.loading = true;
      state.statusChangeFlag = true;
    },
    [imageStausUpdate.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        // state.videoGalleryData = action.payload.results.res;
        state.loading = false;
        state.submitting = false;
        state.statusChangeFlag = false;

      }
    },
    [imageStausUpdate.rejected]: (state, action) => {
      state.loading = false;
      state.statusChangeFlag = false;
    },


    //--------------------FOR MULTI-DELETE IMAGE GALLERY----------------------------//


    [getImageGallerymultidelete.pending]: (state, action) => {
      state.loading = true;
      state.multiDeleteFlage = true

    },
    [getImageGallerymultidelete.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.successmsg = 'Deleted Successfully';
        state.multiDeleteFlage = false;
        state.submitting = false;



      }
    },
    [getImageGallerymultidelete.rejected]: (state, action) => {
      state.loading = false;
      state.successmsg = 'Something went wrong';
      state.multiDeleteFlage = false;

    },


    //---------------------MULTI-EDIT IMAGE GALLERY------------------------//


    [getImageGallerymultiedit.pending]: (state, action) => {
      state.loading = true;
      state.multiEditFlage = true;

    },
    [getImageGallerymultiedit.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.successmsg = 'Updated Successfully 222222222222222222222';
        state.multiEditFlage = false;
        state.submitting = false;


      }
    },
    [getImageGallerymultiedit.rejected]: (state, action) => {
      state.loading = false;
      state.multiEditFlage = false;
      state.successmsg = 'Something went wrong';

    },


    //------------------------------------------------//





    //////////////////////////////////////////////////////////////////////////////////////////////
    [getImageSeachList.pending]: (state, action) => {
      state.loading = true;
      state.imageGalleryData = null;
      state.message = null;
    },
    [getImageSeachList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
        state.imageGalleryData = action.payload.respData.results.res;
        state.imageListCount = action.payload.respCount.count;
        state.message = action.payload.respData.status;
      }
    },
    [getImageSeachList.rejected]: (state, action) => {
      state.loading = false;

    },
  }
})

export const { setEditDataForImage, clearEditDataForImage, clearImageGalList, clearStatusChangeFlag, clearMultiDeleteFlage, clearMultiEditFlage,clearImgGalDetail } = imagegallerySlice.actions;

export default imagegallerySlice.reducer;