import React from 'react'
import '../AdvancedBiologics/AdvancedBiologics.css'
import Amnion from './Amnion/Amnion'
import Dermacyte from './Dermacyte/Dermacyte'
import WoundCare from './WoundCare/WoundCare'

import "../AdvancedBiologics/AdvancedBiologics.css";
import Xwrap from './Xwrap/Xwrap'

export default function AdvancedBiologics() {
  return (
    <>
 
 
      <div className="Healthriskanalysis_banner abbaner common_banner_sec advance_banner">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg1">
              <div className="banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_header">
                      <h1 >
                      Advanced Biologics
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Healthriskanalysis_block2  abbaner_block2">
        <div className="common_width">
          <div className="Healthriskanalysis_block2_subwrapper abbaner_block2_subwrapper">
            <img
              src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/hpcblock2img.webp"
              className="abimage"
            />
            <div className="textwrapper">
              <h2>The Future of Regenerative medicine:</h2>

              <p>
                <span>
                  n the present day context regenerative medicine is the future
                  of wound care.{" "}
                </span>{" "}
                The utilization of the power of cell-derived signaling molecules
                has been proven to be a more effective means for wound healing.
                It isn’t just about the cell, rather it’s about the regenerative
                qualities of cell-secreted proteins. We are pioneering the use
                of cell-derived proteins and nano vesicles derived from
                immunologically privileged tissues for a variety of
                applications, providing better healthcare solutions and outcomes
                for both patients and providers.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
     
  <div className="Healthriskanalysis_block3 abbaner_block3">
        
    <div className="abbaner_block3_textwraper"> 
        <div className="common_width">
          <div className="textwrapper">
            <h2>4 Types of wounds we specialize in:</h2>

            <div className="block_listwrapper green_bullet_point">
              <p>
                {" "}
                <span>Arterial pressure ulcers: </span> Arterial pressure ulcers
                result from skin and soft tissue ischemia due to
                arterial-occlusive disease. They are associated with
                hypertension, diabetes, chronic kidney disease, and smoking.
                Various methods of pressure offloading have strong evidence of
                effectiveness in prevention of pressure ulcers.
              </p>
              <p>
                <span>Venous pressure ulcers: </span>Venous ulcers typically
                occur because of damage to the valves inside the leg veins.
                These valves control the blood pressure inside the veins. They
                allow it to drop when you walk. If the blood pressure inside
                your leg veins doesn’t fall as you’re walking, the condition is
                called sustained venous hypertension. That increase in blood
                pressure cause ulcers to form on the ankles.
              </p>
              <p>
                <span>Diabetic neuropathy wounds: </span>Diabetic foot ulceration
                is a serious complication of diabetes mellitus worldwide and the
                most common cause of hospitalization in diabetic patients. The
                etiology of diabetic foot ulcerations is complex due to their
                multifactorial nature; in the pathophysiology of diabetic foot
                ulceration polyneuropathy is important. Proper adherence to
                standard treatment strategies and interdisciplinary cooperation
                can reduce the still high rates of major amputations.
              </p>
              <p>
                {" "}
                <span>Pressure injury: </span>Pressure injury also known as
                pressure ulcers are decubitus ulcers - are injuries to skin and
                underlying tissue resulting from prolonged pressure on the skin.
                Pressure injury most often develop on skin that covers bony
                areas of the body, such as the heels, ankles, hips and tailbone.
                People most at risk of pressure injury have medical conditions
                that limit their ability to change positions or cause them to
                spend most of their time in bed or chair.
              </p>
            </div>
           
          </div>
        </div>
        </div>
      </div>
      <div className='abbaner_block4'>
        <div className='common_width'>
          <div className='textwrapper'>
            <h2>What do we Offer?</h2>
            <p>Specialized Grafting for the healing of chronic and complicated wounds</p>
            <p>Expedite wound closure between 4-6 weeks on average</p>
            <a href='https://all-frontend-assets.s3.amazonaws.com/iwc_pdf/PRODUCTS_FOR_GENERAL_WOUND_CARE_MANAGEMENT.pdf' target="_blank"><button className='banner4_button'> Natural Biosynthetic Products</button></a>
            <p>Treatment of Diabetic Ulcers, Pressure Sores, Trauma Wounds and Burns. Expedited Wound Closure for Pain Reduction and Infection Control</p>
            <p>A potential alternative to leg amputation, healing with native tissue and less scarring.</p>
            
            <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/hpcblockhexpng.webp" className='abimage' />
          </div>
        </div>
        <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/hpcblock4nurcing.webp" className='abimage1' />
      </div>
      <WoundCare/>
      <Dermacyte/>
       <Amnion/>
       <Xwrap/>
     
    </>
  );
}
