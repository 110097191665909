import React from 'react'
import "./Amnion.css"

const Amnion = () => {
    return (
        <> 
            <div className='amnion_main_wrap'>
                <div className='amnion_wrap'>
                    <div className='amnion_content_wrap'>
                        <h2>Amnion provides ideal regenerative scaffold
                            for cell proliferation and differentiation</h2> 
                        <div className='content_one'>
                            <p>Unique Healing Properties of Amnion</p>
                            <p>Scarless fetal wound healing:<span className='text_blue'> A basic science review</span></p>
                            <p><span className='text_blue'>Barrett J. Larson, B. S., Michael T. Longaker, M.D., M.B.A., and H. Peter Lorenz, M.D. Hagey Laboratory </span>for pediatric regenerative medicine, division of plastic surgery, department of surgery, Stanford university school of medicine</p>
                        </div>

                        <div className='content_two'>
                            <p>Summary</p>
                            <p>Scar formation is a major medical problem that can have devastating consequences for patients. The adverse physiological effects of scars are broad, and there are
                                currently no reliable treatments to prevent scarring. In contrast to adult wound, early gestation fetal skin wound repair rapidly and in the absence of scar formation. Despite extensive investigation, the exact mechanisms of scarless fetal wound healing remain largely unknown. For some time, it has been known that significant differences exist among the extracellular matrix, inflammatory response, cellular mediators, and gene expression profiles of fetal and postnatal wound. These differences may have
                                important implications in scarless wound repair.</p>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Amnion