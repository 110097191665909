import { configureStore } from "@reduxjs/toolkit";

 
// import formSlice from './form/formReducer';
// import tableSlice from './listing/listReducer';
// import blogSlice from "./Pages/BlogBackend/BlogReducer";
 
 
import loginSlice from './Pages/Login/loginReducer'
import formSlice from './form/formReducer'
import tableSlice from './listing/listReducer'
import searchReducer from "./search/searchReducer";
import layoutSlice from './Layout/layoutReducer';

import teamManagementSlice from './Pages/TeamManagementBackend/teamManagementReducer'

import userDashboardKey from './Pages/AdminDashboardBackend/adminDashboardReducer'
import testimonialReducer from "./Pages/TestimonialBackend/testimonialReducer";
import imagegallerySlice from "./Pages/GalleryBackend/ImageGalleryBackend/ImageGalleryReducer";
import videogallerySlice from "./Pages/GalleryBackend/VideoGalleryBackend/VideoGalleryReducer";
import blogSlice from "./Pages/BlogBackend/BlogReducer";
import fileUploaderSlice from "./form/fileUploaderReducer";
import accountSlice from "./Pages/MyAccount/AccountReducer";
import locationManagementReducer from "./Pages/LocationManagementBackend/LocationManagementReducer";
import socialMediaAdvoSlice from "./Pages/SocialMediaAdvoBackend/socialMediaAdvoReducer";
import calenderSlice from './Pages/CalenderManagementBackend/CalenderReducer'
import bannerSlice from './Pages/BannerManagement/BannerReducer'




export default configureStore({
  reducer: {
 
    // formSlice,
    // tableSlice,
    // blogSlice

    loginSlice,
    formSlice,
    tableSlice,
    layoutSlice,
    searchSlice: searchReducer,
    teamManagementSlice,
    userDashboardKey,
    tesTimonialKey: testimonialReducer,
    imagegallerySlice,
    videogallerySlice,
    blogSlice,
    accountSlice,
    fileUploaderSlice,
    locationManagementReducer,
    socialMediaAdvoSlice,
    bannerSlice,
    calenderSlice
  },
});