import React from 'react'
import "./Dermacyte.css"


const Dermacyte = () => {
  return (
    <>
    <div className='derm_main_wrap'>
        <div className='derm_wrap'>
            <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/dermtyimg2.webp" alt='' className='dert_pro_img'/>
            
            <div className='derm_content_wrap'>
                <div className='derm_content_head'>
                    <h1>Dermacyte:</h1>
                    <h2>AMNION ANATOMY & PHYSIOLOGY</h2>
                    <p>Anatomy</p>
                    <p>Amnion (inner layer)</p>
                    <p>Chorion (outer layer)</p>
                </div>
                <h3>Physiology</h3>
                <div className='derm_list_wrap'>
                    <div className='left_list'>
                      <ul>
                        <li>Collagen Type - I, III, IV, V, and VI</li>
                        <li>Elastin</li>
                        <li>Hyaluronic Acid</li>
                        <li>Transforming Growth Factor Beta (TGF-β)</li>
                        <li>Fibroblast Growth Factors (FGFs)</li>
                        <li>Platelet Derived Growth Factors (PDGF) A&B</li>
                        <li>Proteins and vesicles IN SOLUTION</li>
                      </ul>
                    </div>
                    <div className='right_list'>
                      <ul>
                      <li>Proteins</li>
                        <ul>
                            <li>CHEMOKINES (E.G. CXCL 14)</li>
                            <li>CYTOKINES (E.G. ADIPONECTIN)</li>
                            <li>GROWTH FACTORS</li>
                        </ul>
                      <li>Extracellular Vesicles (e.g. exosomes)</li>
                      </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Dermacyte