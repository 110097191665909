import React from 'react'
import '../Healthriskanalysis/Healthriskanalysis.css'

function Healthriskanalysis() {
    return (
        <>
            <div className='Healthriskanalysis_banner common_banner_sec'>
                <div className='banner_body'>
                    <div className='banner_wrapper'>
                        <div className='banner_wrpr_bg1'>
                            <div className='banner_con'>
                                <div className='common_width'>
                                    <div className='banner_con_text'>
                                        <div className='banner_con_text1'>
                                            <h1>Health Risk Analysis  <br /> <span>Our cutting edge early detection exam</span></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Healthriskanalysis_block2'>
                <div className='common_width'>
                    <div className='Healthriskanalysis_block2_subwrapper'>
                        <img src={`${process.env.REACT_APP_IMG_URL}Healthriskanalysis_image2.webp`} className='abimage' />
                        <div className='textwrapper'>
                            <h2>Our powerful Health Risk Analysis</h2>
                            <h3> Early detection is the key to living a disease free life!</h3>
                            <p><span>Early detection could save your life. </span> Come see us today for our Health Risk Analysis.
                                We test for the early signs of over 20 life threatening disease states including heart attack risk, diabetes and other chronic disease in less than 15 minutes. </p>
                            <p>Common symptoms like headaches, heartburn, dizziness, fatigue, and tingling feet could be more serious than you think.
                                <span> For a list of symptoms and a way to sign up now to get prequalified for our exam click here. </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Healthriskanalysis_block3'>
                <img src={`${process.env.REACT_APP_IMG_URL}Healthriskanalysis_image3.webp`} className='abimage' />
                
                <div className='common_width'>
                    <div className='textwrapper'>
                        <h2>Covered by most insurance
                            including Medicare!</h2>
                        <p>According to the CDC, nearly 900,000 Americans die prematurely each year. Up to 40% of these deaths could have been prevented! Please take one for you or your loved ones! </p>
                        <h3>Our quick, easy and non-invasive exam tests for:</h3>
                        <div className='block_listwrapper green_bullet_point'>
                            <p>Heart Attack Risk</p>
                            <p>Diabetes with Complications</p>
                            <p>Post Covid Complications</p>
                            <p>Nerve Damage (including that which can lead to organ failure)</p>
                            <p>Artery Inflammation and Disease</p>
                            <p> Obesity Complications</p>
                        </div>
                        <a>Plus many more…</a>
                    </div>
                </div>
            </div>
            <div className='green_bar'>
            <div className='green_bar_subwrapper'>
                <div className='greenbar_left'>
                        <h2>Get started by coming in for our <span>Health Risk Analysis!</span> <img src={`${process.env.REACT_APP_IMG_URL}Healthriskanalysis_arrow.png`} /></h2>
                </div>
                <div className='greenbar_right'>
                        <button className='wh_btn'> <a href='https://qualitypracticeweb.com/smith1111111'>Click here</a></button>
                </div>
            </div>
            </div>

            <div className='Healthriskanalysis_block4'>
                
                <div className='Healthriskanalysis_block4_subblock1'>
                    <div className='common_width'>
                        <div className='textwrapper'>
                            <p> <span>We work with the latest cutting edge science!</span>  If you have one or several of the following symptoms, don’t hesitate to come see our friendly and knowledgeable medical team. These common symptoms could be more serious than you think. Early detection could even save your life. </p>
                        </div>
                        </div>
                </div>
                <div className='Healthriskanalysis_block4_subblock2'>
                    <img src={`${process.env.REACT_APP_IMG_URL}Healthriskanalysis_image4.webp`} className='abimage' />
                <div className='common_width'>
                        
                    <div className='Healthriskanalysis_block4_right'>
                    <div className="textwrapper">
                <h2>Common Symptoms are:</h2>
              </div>
              <div className="block_listwrapper green_bullet_point coloumn_count">
               <p>Blurred Vision	</p>
               <p>Elevated Blood Sugar	</p>
               <p>Extreme Thirst</p>
               <p>Frequent Urination</p>
               <p>Lightheadedness</p>
               <p>Dizziness</p>
               <p>Headache</p>
               <p>Fatigue (Tiredness)</p>
               <p>Heartburn	</p>
               <p>Increased Hunger	</p>
               <p>Nausea</p>
               <p>Numbness & Tingling in Hands or Feet</p>
               <p>Vomiting</p>
               <p>Angina (severe chest pain, often spreading to shoulder, arm, back, neck, or jaw)</p>
               <p>Chest Pain</p>
               <p>Pain in Calves</p>
               <p>Shortness of Breath</p>
               <p>Stroke</p>
               <p>TIA (mini-stroke)</p>
               <p>Burning Sensations</p>
               <p>Painful Contact with Socks or Bed Sheets</p>
              </div>
              <div className='textwrapper bold_p'><p>If you are experiencing any of these symptoms, you could be at risk. Schedule an appointment today to find out more! <a href='https://qualitypracticeweb.com/smith1111111' target="_blank">Click here</a>  to see if you pre-qualify and get an appointment.</p></div>
              <div className="textwrapper">
                <h2>Disease States It Detects:</h2>
              </div>
              <div className="block_listwrapper green_bullet_point coloumn_count">
               <p>Heart attack	</p>
               <p>Heart rate variability issues	</p>
               <p>Cardiac output to body surface area</p>
               <p>Abnormal LDL cholesterol levels</p>
               <p>Insulin resistance</p>
               <p>Diabetes with complications</p>
               <p>Physical and mental stress, anxiety, and fatigue</p>
               <p>Stroke</p>
               <p>Angiotensin activity	</p>
               <p>Atherosclerosis	</p>
               <p>Peripheral vascular disease</p>
               <p>Hyperhidrosis and hypohidrosis</p>
               <p>Impaired glucose intolerance</p>
               <p>Nerve damage</p>
               <p>Obesity</p>
               <p>Amyloid neuropathy</p>
               <p>Asthma</p>
               <p>Cardiovascular associated diseases</p>
               <p>Idiopathic neuropathy</p>
               <p>Multiple system dystrophy</p>
               <p>Postpartum dysfunctions</p>
               <p>Psychological conditions</p>
               <p>Autonomic failure</p>
               <p>Sjogren’s syndrome</p>
              </div>

                        {/* <div className='list1 sublist'>
                                <h2>CARDIOMETABOLIC RISK (CMR)</h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Headaches</p>
                                    <p>Dizziness</p>
                                    <p>Swelling of Ankles</p>
                                </div>
                        </div>
                            <div className='list2 sublist'>
                                <h2>AUTONOMIC NERVOUS SYSTEM DYSFUNCTION (ANSD)</h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Fatigue (Tiredness)</p>
                                    <p> Exercise Intolerance</p>
                                    <p>Heartburn</p>
                                    <p>Nausea</p>
                                </div>
                        </div>
                            <div className='list3 sublist'>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Blurred Vision</p>
                                    <p>Difficulty Digesting Food</p>
                                    <p>Elevated Blood Sugar</p>
                                    <p>Sweat Abnormalities</p>
                                    <p>Increased Hunger</p>
                                    <p>Vomiting </p>
                                    <p>Urinary Problems </p>
                                    <p>Burning Sensations</p>
                                    <p>Frequent Urination</p>
                                    <p>Extreme Thirst</p>
                                    <p>Sexual Difficulties</p>
                                    <p> Dizziness</p>             
                                </div>
                        </div>
                            <div className='list4 sublist'>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Fainting</p>
                                    <p>Numbness and/or Tingling in Hands or Feet</p>                                       
                                </div>
                        </div>
                            <div className='list5 sublist'>
                                <h2>CARDIOMETABOLIC AUTONOMIC NEUROPATHY (CAN)</h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Lack of Energy </p>
                                    <p>Lack of Concentration </p>
                                    <p> Depression</p>
                                    <p>Lightheadedness</p>
                                </div>
                            </div>
                            <div className='list6 sublist'>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Fainting</p>
                                    <p>Thirst</p>
                                    <p>Rapid, Shallow Breathing</p>
                                    <p> Cold, Clammy, Pale Skin</p>         
                                </div>
                            </div>
                            <div className='list7 sublist'>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Blurred Vision</p>
                                    <p>Fatigue (Tiredness)</p>
                                    <p>Nausea</p>                                          
                                </div>
                            </div>
                            <div className='list8 sublist'>
                                <h2>INSULIN RESISTANCE (IR)</h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Blurred Vision</p>
                                    <p>Elevated Blood Sugar</p>
                                    <p>Extreme Thirst</p>
                                    <p>Fatigue (Tiredness)</p>
                                    <p>Increased Hunger</p>
                                </div>
                            </div>
                            <div className='list9 sublist'>
                                <h2>ENDOTHELIAL DYSFUNCTION (ENDOD)</h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Shortness of Breath </p>
                                    <p>Pain In Calves</p>
                                    <p>Chest Pain that goes away with rest</p>
                                    <p>TIA (mini stroke)</p>
                                    <p>Stroke</p>
                                    <p>Angina (severe chest pain, often spreading</p>
                                    <p>Heartburn</p>
                            </div>
                            </div>
                            <div className='list10 sublist'>
                                <h2>PLETHYSMOGRAPHY CARDIOVASCULAR DISEASE (PTG CVD)</h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Irregular heartbeat, too fast/slow (Atrial Fibrillation)</p>
                                    <p>Heart Attack </p>
                                    <p>Blood clot in a vein (Venous Thrombosis)</p>
                                    <p>Stroke</p>
                                </div>
                            </div>
                            <div className='list11 sublist'>
                                <h2>SMALL FIBER SENSORY NEUROPATHY (SFN) </h2>
                                <div className='block_listwrapper green_bullet_point'>
                                    <p>Pins And Needles Sensation In Feet</p>
                                    <p>Pebble or Sandlike Sensation In Shoes </p>
                                    <p>Burning Sensations</p>
                                    <p>Stabbing or Electrical Shock Sensation</p>
                                    <p>Painful Contact With Socks or Bed Sheets</p>
                                </div>
                            </div> */}
                        
                    </div>
                </div>
                </div>
            </div>

            <div className='green_bar'>
                <div className='green_bar_subwrapper'>
                    <div className='greenbar_left'>
                        <h2>Get started by coming in for our <span>Health Risk Analysis!</span> <img src={`${process.env.REACT_APP_IMG_URL}Healthriskanalysis_arrow.png`} /></h2>
                    </div>
                    <div className='greenbar_right'>
                    <button className='wh_btn'> <a href='https://qualitypracticeweb.com/smith1111111'>Click here</a></button>
                    </div>
                </div>
            </div>

            <div className='Healthriskanalysis_block5'>
                <div className='Healthriskanalysis_block5_subwrapper'>
                    <img src={`${process.env.REACT_APP_IMG_URL}Healthriskanalysis_image5.webp`} className='abimage' />
                <div className='common_width'>
                        <div className='textwrapper'>
                            <p>Regular doctor practices, even if they do blood labs, do not have the ability to quickly test for all of these disease risks without additional extensive tests.  Often, these health risks go unnoticed in a regular doctor visit examination. Many diseases and sudden early death are due to catching undetected diseases at later stages when it is too late. Our early detection exam covers over 20 potential disease states (see below for full list) across the most vital risk markers available in a <span>15 minute</span> non-invasive test. </p>
                            <p>According to the CDC, nearly 900,000 Americans die prematurely each year. Up to 40% of these deaths could have been prevented!  Breakthroughs in the last decade in early detection have advanced medical professionals' ability to detect disease early. We are one of the few facilities that have adapted to this new detection technology. Helping us get the word out could quite literally save one of your customer’s lives!</p>
                            <p>The CDC states in the United States someone has a heart attack every 40 seconds. <span>Every year, about 805,000 people in the US have a heart attack. 75% of these are first heart attacks!</span>  Again, our Health Risk Analysis catches the symptoms leading to heart attack and a number of other heart complications up to <span>2 years early</span>.  Certainly in time to make a big difference in the future wellbeing of someone that you shared our information with. </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Healthriskanalysis
