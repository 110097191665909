import React from 'react'
import "./testimonialBanner.css"

function TestimonialBanner() {
  return (
   
      <div className='testimo_bnnr_sec common_banner_sec'>
      
      <div className='banner_body'>
            <div className='banner_wrapper'>
              <div className='banner_wrpr_bg1'>

                <div className='banner_con'>

                    <div className='common_width'>

                        <div className='banner_con_text'>

                        <div className='banner_con_text1'>

                            <h1>Testimonials</h1>
                            
                           
                           

                          

                            </div>

                            

                        </div>

                    </div>

                </div>
              </div>
            </div>
        </div>


    </div>
    
  )
}

export default TestimonialBanner
