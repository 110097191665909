import { Store } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../store";

export const getTeamSeachList = createAsyncThunk("team/search/list", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/listingusers", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/listinguserscount", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
});


export const getInitialTeamList = createAsyncThunk("team/list", async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token

    // console.log("reqbody==>", reqbody);
    if (reqbody == null && reqbody == undefined) {
        reqbody = {
            "source": "user_views",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "type": "desc",
                "field": "createdon_datetime"
            },
            'token': accessToken,
            'email': userMail,
            "searchcondition": {

            }
        }
    }

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/listingusers", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




/****************Change Status Team Management******************** */


export const updateStatus = createAsyncThunk("TeamManagement/statuschange", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/changestatus", requestOptions);
    const respdata = await response.json();
    return respdata;

})


/****************Change Priority Team Management******************** */


export const updatePriority = createAsyncThunk("TeamManagement/setpriority", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/setpriority", requestOptions);
    const respdata = await response.json();
    return respdata;

})

/****************Add Notes Priority Team Management******************** */


export const addNotesForUser = createAsyncThunk("TeamManagement/addnotes", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/addnotes", requestOptions);
    const respdata = await response.json();
    return respdata;

})

export const getNotesList = createAsyncThunk("TeamManagement/noteslist", async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token

    // console.log("reqbody==>", reqbody);
    if (reqbody == null && reqbody == undefined) {
        reqbody = {
            "source": "notes_collection_views",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "type": "desc",
                "field": "_id"
            },
            'token': accessToken,
            'email': userMail,
            "searchcondition": {

            }
        }
    }

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/noteslistingforusers", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



/**********Multiple Edit User- Only Change Status*************************** */
export const statusUpdateMultiple = createAsyncThunk("TeamManagement/multiedit", async (reqbody) => {



    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multipleedituserstatus", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


/***************************Multiple Delete- Users***************************** */

export const usermultidelete = createAsyncThunk("TeamManagement/multidelete", async (reqbody) => {
    const accessToken = (Store.getState().loginSlice?.token); /// access token
    const userMail = (Store.getState().loginSlice?.userInfo.email); /// for access token
    let req_body = {};
    req_body = { _ids: reqbody,source:"users", email:userMail, token:accessToken };
    // req_body = { _ids: reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideleteusers", requestOptions);
    const respdata = await response.json();
    return respdata;
});


//---------------------------select field for location Name----------------//

export const fetchLocationName = createAsyncThunk("fetch-location", async (reqbody) => {
    console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = reqbody;
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/selectLocationName", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




const teamManagement = createSlice({
    name: 'teamManagement',
    initialState: {
        message: null,
        loading: false,
        noteloading:false,
        success: false,
        submitted: false,
        submitting: false,
        successmsg: "",
        teamList: [],
        searchTeamList: [],
        userList: [],
        teamCount: null,
        preview: null,
        editDataforuser: {},
        statusChangeFlag: null,
        priorityChangeFlag: null,
        addNotesFlag: null,
        notesList: [],
        notesCount: null,
        maltiDeleteFlage: null,
        statusUpdateFlage: null,
        multiEditFlage:null,
        noteSingleDeleteFlage:null,
        locationName:[],
        prioritySnackbarFlag:null,


    },
    reducers: {
        setSuccessAfterSubmit(state, action) {
            state.success = false;
        },
        resetManageaccountflag(state, action) {
            state.manageAccountflag = {}
        },
        setEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
                state.editDataforuser = action.payload;
            }
        },
        clearEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            state.editDataforuser = {};
        },
        clearUserListing(state, action) {
            state.teamList = [];
        },
        clearTeamList(state, action) {
            state.userList = [];
        },
        clearPriorityFlag(state, action) {
            state.priorityChangeFlag = null;
        },
        clearNotesFlag(state, action) {
            state.addNotesFlag = null;
        },
        clearmaltiDeleteFlage(state, action) {
            state.maltiDeleteFlage = null;
        },
        clearstatusUpdateFlage(state, action) {
            state.statusUpdateFlage = null;
        },
        clearMultiEditFlage(state, action){
            state.multiEditFlage = null;
          },
          clearnoteSingleDeleteFlage(state, action) {
            state.noteSingleDeleteFlage = null;
        },
        clearnotesList(state, action) {
            state.notesList = [];
        },

        clearprioritySnackbarFlag(state, action){
            state.prioritySnackbarFlag = null;
          },
    },
    extraReducers: {
        [getTeamSeachList.rejected]: (state, action) => {
            state.loading = false;
        },
        [getTeamSeachList.pending]: (state, action) => {
            state.loading = true;
            state.teamList = null;
            state.message = null;
        },
        [getTeamSeachList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
                state.teamList = action.payload.respData.results.res;
                state.searchTeamList = action.payload.respData.results.res;
                
                state.teamCount = action.payload.respCount.count;
                state.message = action.payload.respData.status;
            }
        },
        [getInitialTeamList.pending]: (state, action) => {
            state.loading = true;
            state.teamList = null;
        },
        [getInitialTeamList.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {

                state.teamList = action.payload.results.res;

                state.loading = false;

                state.userList = [...state.userList, ...action.payload.results.res];


            }
        },
        [getInitialTeamList.rejected]: (state, action) => {
            state.loading = false;
        },

        //******************Update Status**************************** */

        [updateStatus.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.statusChangeFlag = false
            state.statusUpdateFlage = true;
        },
        [updateStatus.fulfilled]: (state, action) => {

            if (action.payload.status == 'success') {
                state.loading = false;
                //   state.success = true;
                state.submitting = false;
                state.statusChangeFlag = false;
                state.statusUpdateFlage = false;
            }
        },

        [updateStatus.rejected]: (state, action) => {
            state.loading = false;
            state.statusChangeFlag = false;
            state.statusUpdateFlage = false;
        },

        //******************Update Priority**************************** */
       
        [updatePriority.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.priorityChangeFlag = true;
        },
        [updatePriority.fulfilled]: (state, action) => {

            if (action.payload.status == 'success') {
                state.loading = false;
                //   state.success = true;
                state.submitting = false;
                state.priorityChangeFlag = false;
            }
        },

        [updatePriority.rejected]: (state, action) => {
            state.loading = false;
            state.priorityChangeFlag = false;
        },

        //******************Add Notes**************************** */
        [addNotesForUser.rejected]: (state, action) => {
            state.loading = false;
            state.addNotesFlag = false;
        },
        [addNotesForUser.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.addNotesFlag = false
        },
        [addNotesForUser.fulfilled]: (state, action) => {

            if (action.payload.status == 'success') {
                state.loading = false;
                //   state.success = true;
                state.submitting = false;
                state.addNotesFlag = false;
            }
        },

        [getNotesList.pending]: (state, action) => {
            state.noteloading = true;
            state.notesList = null;
        },
        [getNotesList.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {

                state.notesList = action.payload.results.res;
                // state.notesCount = action.payload.respCount.count;
                state.noteloading = false;

            }
        },
        [getNotesList.rejected]: (state, action) => {
            state.noteloading = false;
        },


        /*******************Multiple Edit Users - Only status change********** */
        [statusUpdateMultiple.pending]: (state, action) => {
            state.loading = true;
            state. multiEditFlage =true;
        },
        [statusUpdateMultiple.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Updated Successfully'
                state.success = true;
                state. multiEditFlage =false;
            }
        },
        [statusUpdateMultiple.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
            state. multiEditFlage =false;
        },

        /*-------------------------- Delete Multiple Testimonial--------------------------*/


        [usermultidelete.pending]: (state, action) => {
            state.loading = true;
            state.maltiDeleteFlage = true;
        },
        [usermultidelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.maltiDeleteFlage = false;
                state.successmsg = 'Deleted Successfully';

            }

        },
        [usermultidelete.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
            state.maltiDeleteFlage = false;

        },
//-----------------------select field for location name-----------------------//

        [fetchLocationName.pending]: (state, action) => {
            // state.loading = true;
            state.locationName = null;
     },
 
     [fetchLocationName.fulfilled]: (state, action) => {
         if (action.payload.status === 'success') {
             state.locationName = action.payload.results.res;
            //  state.loading = false;
         }
     },
 
     [fetchLocationName.rejected]: (state, action) => {
        //  state.loading = false;
         state.successmsg = 'Something went wrong';
     },
 
     




    }
})


export default teamManagement.reducer;
export const { setSuccessAfterSubmit,clearTeamList, resetManageaccountflag, setEditData, clearEditData, clearUserListing, clearPriorityFlag,clearnotesList, clearNotesFlag, clearmaltiDeleteFlage,clearstatusUpdateFlage,clearMultiEditFlage,clearprioritySnackbarFlag } = teamManagement.actions;