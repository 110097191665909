import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../store";

//////////////////////////// For user list ////////////////////////
export const getdataforBannerlist = createAsyncThunk(
    "getdataforBannerlist",
    async (reqBody) => {
        const accessToken = (store.getState().loginSlice?.token); /// access token
        const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
        if (reqBody == null && reqBody == undefined) {
            reqBody = {
                "source": "banner_views",
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "type": "desc",
                    "field": "createdon_datetime"
                },
                'token': accessToken,
                'email': userMail,
                "searchcondition": {}
            }
        }
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody),
        };
        const response = await fetch(`${process.env.REACT_APP_API_URL}api1/bannerlisting`, requestOptions);
        console.log("Next 222 step", response)
        const res = await response.json();
        return res;
    }
);


export const getBannerSeachList = createAsyncThunk("getBannerSeachList", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/bannerlisting", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/bannerlistingcount", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
});

// ---------------------------------------------For Updating an image--------------------------------------------- //
export const addUpdateBanner = createAsyncThunk("addUpdateBanner", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/addupdatebanner", requestOptions);
    const respData = await responseData.json();
    return respData;
});

// ----------------------------------------For Changing Status of an image---------------------------------------- //
export const statusChangeBanner = createAsyncThunk("statusChangeBanner", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/bannerstatusupdate", requestOptions);
    const respData = await responseData.json();
    return respData;
});

// -------------------------------------------API CALL FOR MULTI-DELETE------------------------------------------- //
export const bannerMultidelete = createAsyncThunk("bannerMultidelete", async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    let req_body = {};
    req_body = { _ids: reqbody, source: "banner_schemas", email: userMail, token: accessToken  };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideletebanner", requestOptions);
    const respdata = await response.json();
    return respdata;
});

// --------------------------------------------API CALL FOR MULTI-EDIT-------------------------------------------- //
export const bannerMultiedit = createAsyncThunk("bannerMultiedit", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multieditbanner", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const topBannerData = createAsyncThunk("topBannerData", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/bannerlisting", requestOptions);
    const res = await response.json();
    return res;
});

export const bottomBannerData = createAsyncThunk("bottomBannerData", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/bannerlisting", requestOptions);
    const res = await response.json();
    return res;
});




// ----------------------------------------------Image Gallery Slice---------------------------------------------- //
const bannerSlice = createSlice({
    name: 'bannerSlice',
    initialState: {
        loading: false,
        message: null,
        bannerData: null,
        loadMore: false,
        bannerListCount: null,
        statusChangeFlag: null,
        editDataForBanner: {},
        successmsg: "",
        success: false,
        submitted: false,
        submitting: false,
        multiDeleteFlage: null,
        multiEditFlage: null,
        topBanner:null,
        bottomBanner:null

    },


    reducers: {
        setEditDataForBanner(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
                state.editDataForBanner = action.payload;
            }
        },
        clearEditDataForBanner(state, action) {
            console.log("state from seteditdata==>", state, action);
            state.editDataForBanner = null;
        },
        clearStatusChangeFlag(state, action) {
            state.statusChangeFlag = null;
        },
        clearMultiDeleteFlage(state, action) {
            state.multiDeleteFlage = null;
        },
        clearMultiEditFlage(state, action) {
            state.multiEditFlage = null;
        },
        setSuccessAfterSubmit(state, action) {
            state.success = false;
        },
    },
    extraReducers: {
        ////////////////////////////////Banner List////////////////
        [getdataforBannerlist.pending]: (state, action) => {
            state.loading = true;
        },
        [getdataforBannerlist.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {

                state.bannerData =action.payload.results.res;
                state.loading = false;
      }
        },
        [getdataforBannerlist.rejected]: (state, action) => {
            state.loading = false;
        },


        //////////////////////////////////////////////////////////
        [topBannerData.pending]: (state, action) => {
            state.loading = true;
        },
        [topBannerData.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success" && action.payload.results.res[0].position===1 ) {

                state.topBanner =action.payload.results.res;
                state.loading = false;
      }
        },
        [topBannerData.rejected]: (state, action) => {
            state.loading = false;
        },
        //////////////////////////////////////////////////////////////
        [bottomBannerData.pending]: (state, action) => {
            state.loading = true;
        },
        [bottomBannerData.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success" && action.payload.results.res[0].position===2 ) {

                state.bottomBanner =action.payload.results.res;
                state.loading = false;
      }
        },
        [bottomBannerData.rejected]: (state, action) => {
            state.loading = false;
        },

        ////////////////////////////////add Banner////////////////
        [addUpdateBanner.pending]: (state, action) => {
            state.loading = true;
        },
        [addUpdateBanner.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.submitting = false;

            }
        },
        [addUpdateBanner.rejected]: (state, action) => {
            state.loading = false;
        },
        ///////////////////////////////////////////////////////////////////////////////////////
        [statusChangeBanner.pending]: (state, action) => {
            state.loading = true;
            state.statusChangeFlag = true;
        },
        [statusChangeBanner.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.submitting = false;
                state.statusChangeFlag = false;

            }
        },
        [statusChangeBanner.rejected]: (state, action) => {
            state.loading = false;
            state.statusChangeFlag = false;
        },


        //--------------------FOR MULTI-DELETE Banner---------------------------//


        [bannerMultidelete.pending]: (state, action) => {
            state.loading = true;
            state.multiDeleteFlage = true

        },
        [bannerMultidelete.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Deleted Successfully';
                state.multiDeleteFlage = false;
                state.submitting = false;



            }
        },
        [bannerMultidelete.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
            state.multiDeleteFlage = false;

        },


        //---------------------MULTI-EDIT Banner------------------------//


        [bannerMultiedit.pending]: (state, action) => {
            state.loading = true;
            state.multiEditFlage = true;

        },
        [bannerMultiedit.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Updated Successfully';
                state.multiEditFlage = false;
                state.submitting = false;


            }
        },
        [bannerMultiedit.rejected]: (state, action) => {
            state.loading = false;
            state.multiEditFlage = false;
            state.successmsg = 'Something went wrong';

        },


        //------------------------------------------------//





        //////////////////////////////////////////////////////////////////////////////////////////////
        [getBannerSeachList.pending]: (state, action) => {
            state.loading = true;
            state.bannerData = null;
            state.message = null;
        },
        [getBannerSeachList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
                state.bannerData = action.payload.respData.results.res;
                state.bannerListCount = action.payload.respCount.count;
                state.message = action.payload.respData.status;
            }
        },
        [getBannerSeachList.rejected]: (state, action) => {
            state.loading = false;

        },
    }
})

export const { setEditDataForBanner, clearEditDataForBanner, clearStatusChangeFlag, clearMultiDeleteFlage, clearMultiEditFlage,setSuccessAfterSubmit } = bannerSlice.actions;

export default bannerSlice.reducer;