import React from 'react'
import "./WoundCare.css"



const WoundCare = () => {
    return (
        <>
            <div className='woundHomeBlock'>
                <div className='wound_common_width'>

                    <div class="wtextwrapper">
                        <h2>Advanced Wound Care Products:</h2>
                          
                        <p>Our advanced wound care products are specifically designed for
                            regenerative wound care which is all about the regenerative
                            qualities of the cell-secreted proteins. </p>

                            <h3>Our advanced wound  care products help:</h3>
                            <div className='wound_list_wrap'>
                                <p>
                                <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/woundright.png" alt='' /> Repair chronic wound 
                                <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/woundright.png" alt='' />Diabetic foot ulcers
                                </p>
                                <p>
                                <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/woundright.png" alt='' />Venous leg ulcers 
                                <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/woundright.png" alt='' />Pressure ulcers</p>
                            </div>
                    </div>
                    <div className='wound_img_wrap'>
                        <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/woundimg.webp" />
                    </div>
                </div> 

            </div>
        </>
    )
}

export default WoundCare