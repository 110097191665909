import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import './assets/angular-material-css/deeppurple-amber.css';
import './assets/angular-material-css/indigo-pink.css';
// import './assets/angular-material-css/pink-bluegrey.css';
// import './assets/angular-material-css/purple-green.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './index.css';

import { Cookies } from 'react-cookie';
import Aboutus from './Pages/Aboutus/Aboutus';
import Enfdtesting from './Pages/Enfdtesting/Enfdtesting';
import Healthriskanalysis from './Pages/Healthriskanalysis/Healthriskanalysis';
import MeetOurProviders from './Pages/Meet-our-Providers/MeetOurProviders';
import Testimonials from './Pages/Testimonials/Testimonials';
import Contact from './Pages/Contact/Contact';
import { setLogedinUserInfo } from './Pages/Login/loginReducer';
import AdvancedBiologics from './Pages/AdvancedBiologics/AdvancedBiologics';



// import SmallFiberNeuropathy from './Pages/SmallFiberNeuropathy/SmallFiberNeuropathy';




const Layout = React.lazy(() => import('./Layout/Layout'));
const NoPage = React.lazy(() => import('./Pages/NoPage/NoPage'));
const Login = React.lazy(() => import('./Pages/Login/Login'));



// =============================FRONTEND PAGES Lazy Loading=======================================================

const Home = React.lazy(() => import('./Pages/Home/Home'));

const PharmaCoGenomics = React.lazy(() => import('./Pages/PharmaCoGenomics/PharmaCoGenomics'));
const PeripheralArteryDisease = React.lazy(() => import('./Pages/PeripheralArteryDisease/PeripheralArteryDisease'));
const SmallFiberNeuropathy = React.lazy(() => import('./Pages/SmallFiberNeuropathy/SmallFiberNeuropathy'));
const Neuropathy = React.lazy(() => import('./Pages/Neuropathy/Neuropathy'));
const MedicalFoods = React.lazy(() => import('./Pages/MedicalFoods/MedicalFoods'));
const Blog = React.lazy(() => import('./Pages/Blog/Blog'));
const BlogDetails = React.lazy(() => import('./Pages/Blog/BlogDetails'));
const ImageGallery = React.lazy(() => import('./Pages/Gallery/ImageGallery/ImageGallery'));
const ImageDetails = React.lazy(() => import('./Pages/Gallery/ImageGallery/ImageDetails'));
const VideoGallery = React.lazy(() => import('./Pages/Gallery/VideoGallery/VideoGallery'));
const TestimonialDetails = React.lazy(() => import('./Pages/Testimonials/TestimonialDetails'))




// =============================BACKEND PAGES Lazy Loading=======================================================


const TestimonialBackend = React.lazy(() => import('./Pages/TestimonialBackend/TestimonialBackend'));
const TeamManagementBackend = React.lazy(() => import('./Pages/TeamManagementBackend/TeamManagementBackend'));
const BlogBackend = React.lazy(() => import('./Pages/BlogBackend/BlogBackend'));
const SocialMediaAdvoBackend = React.lazy(() => import('./Pages/SocialMediaAdvoBackend/SocialMediaAdvoBackend'));
const AdminDashboardBackend = React.lazy(() => import('./Pages/AdminDashboardBackend/AdminDashboardBackend'));
const AddUsersBackend = React.lazy(() => import('./Pages/AddUsersBackend/AddUsersBackend'))
const CalendarManagementBackend = React.lazy(() => import('./Pages/CalenderManagementBackend/CalendarManagementBackend'))
const VideoGalleryBackend = React.lazy(() => import('./Pages/GalleryBackend/VideoGalleryBackend/VideoGalleryBackend'));
const ImageGalleryBackend = React.lazy(() => import('./Pages/GalleryBackend/ImageGalleryBackend/ImageGalleryBackend'));
const MyAccount = React.lazy(() => import('./Pages/MyAccount/MyAccount'));
const ForgotPassword = React.lazy(() => import('./Pages/MyAccount/ForgotPassword'));
const ForgotPasswordPage = React.lazy(() => import('./Pages/MyAccount/ForgotPasswordPage'));
const LocationManagementBackend = React.lazy(() => import('./Pages/LocationManagementBackend/LocationManagementBackend'));
const AddLocationBackend = React.lazy(() => import('./Pages/AddLocationBackend/AddLocationBackend'))
const BannerBackend = React.lazy(() => import('./Pages/BannerManagement/BannerBackend/BannerBackend'));


////////////////////////////////////////// Get Cookies from browser and set into Store ////////////////////////////////////
const getCookiesSetStore = () => {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  console.log("getcookies from index==>", getcookies);
  if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
    console.log('getcookies==>', getcookies)
    store.dispatch(setLogedinUserInfo(getcookies));
  }
}
////////////////////////////////// Call the getCookiesSetStore function /////////////////////////////////////
getCookiesSetStore();


const useAuth = () => {
  console.log("AUTHED++++++++++", store.getState().loginSlice.isLoggedIn)
  return store.getState().loginSlice.isLoggedIn;
}
const LoginRoute = ({ children, redirectPath }) => {
  console.log("index---------children", children, "index---------redirectPath", redirectPath);
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
}

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  return (
    auth === true ? children : <Navigate to="/login" replace={true} />
  )
}
const PasswordRoute = ({ children }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to="/login" replace={true} />
}


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* ------------------------------- Login Route ---------------------------------- */}

          <Route path='/' element={<Suspense fallback={<></>}><Layout /></Suspense>}>
            {/* ------------------------------------ Frontend Pages ------------------------------ */}

            {/* ++++++++++++++ Home +++++++++++++++++ */}
            <Route index element={<Suspense fallback={<></>}><Home /></Suspense>} />
            <Route path='/pharmacogenomics' element={<Suspense fallback={<></>}><PharmaCoGenomics /></Suspense>} />
            <Route path='/peripheral-artery-disease' element={<Suspense fallback={<></>}><PeripheralArteryDisease /></Suspense>} />
            <Route path='/about-us' element={<Suspense fallback={<></>}><Aboutus /></Suspense>} />
            <Route path='/small-fiber-neuropathy' element={<Suspense fallback={<></>}><SmallFiberNeuropathy /></Suspense>} />
            <Route path='/enfd-testing' element={<Suspense fallback={<></>}><Enfdtesting /></Suspense>} />
            <Route path='/health-risk-analysis' element={<Suspense fallback={<></>}><Healthriskanalysis /></Suspense>} />
            <Route path='/advanced-biologics' element={<Suspense fallback={<></>}><AdvancedBiologics /></Suspense>} />

            <Route path='/neuropathy-treatment' element={<Suspense fallback={<></>}><Neuropathy /></Suspense>} />
            <Route path='/contact' element={<Suspense fallback={<></>}><Contact /></Suspense>} />
            <Route path='/medical-foods' element={<Suspense fallback={<></>}><MedicalFoods /></Suspense>} />
            <Route path='/meet-our-providers' element={<Suspense fallback={<></>}><MeetOurProviders /></Suspense>} />
            <Route path='/testimonials' element={<Suspense fallback={<></>}><Testimonials /></Suspense>} />
            <Route path='frontend/testimonial-details/:_id' element={<Suspense fallback={<></>}><TestimonialDetails /></Suspense>} />
            <Route path='frontend/testimonials/add/:param' element={<Suspense fallback={<></>}><TestimonialBackend type='frontend-add' /></Suspense>} />
            <Route path='blog' element={<Suspense fallback={<></>}><Blog /></Suspense>} />
            <Route path='blog-detail/:_id' element={<Suspense fallback={<></>}><BlogDetails /></Suspense>} />
            <Route path='videogallery' element={<Suspense fallback={<></>}><VideoGallery /></Suspense>} />
            <Route path='videogallery/:_id' element={<Suspense fallback={<></>}><VideoGallery /></Suspense>} />
            <Route path='/img-gallery' element={<Suspense fallback={<></>}><ImageGallery /></Suspense>} />
            <Route path='img-details/:_id' element={<Suspense fallback={<></>}><ImageDetails type='imgDetails' /></Suspense>} />





            {/* ------------------------------------ Backend Pages ------------------------------ */}
            {/* <Route path='blog/list' element={<Suspense fallback={<></>}><BlogBackend type='list' /></Suspense>} />
            <Route path='blog/add' element={<Suspense fallback={<></>}><BlogBackend type='add' /></Suspense>} />
            <Route path='blog/edit' element={<Suspense fallback={<></>}><BlogBackend type='edit' /></Suspense>} /> */}

            <Route path="login" element={<LoginRoute redirectPath={`/${(store?.getState()?.loginSlice?.userType)}/dashboard`}><Suspense fallback={<></>}><Login /></Suspense></LoginRoute>} />
            {/* ++++++++++++++ Team Management +++++++++++++++++ */}
            <Route path='team/list' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-admin' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editAdmin' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-patient' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editPatient' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-doctor' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editDoctor' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-doctor-assistant' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editDoctorAssistant' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-social-advocate' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editSocialAdvocate' /></Suspense></PrivateRoute>} />

            {/* ++++++++++++++ Location Management +++++++++++++++++ */}
            <Route path='location/list' element={<PrivateRoute><Suspense fallback={<></>}><LocationManagementBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='/location/add-location' element={<PrivateRoute><Suspense fallback={<></>}><AddLocationBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='/location/edit-location' element={<PrivateRoute><Suspense fallback={<></>}><AddLocationBackend type='edit' /></Suspense></PrivateRoute>} />
            {/*++++++++++++++++++++++++++++++++++Backend Banner++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}

            <Route path='banner/list' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='banner/add' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='banner/edit' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='edit' /></Suspense></PrivateRoute>} />

            {/* ++++++++++++++ Testimonial +++++++++++++++++ */}
            <Route path='testimonial/list' element={<PrivateRoute><Suspense fallback={<></>}><TestimonialBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='testimonial/add' element={<PrivateRoute><Suspense fallback={<></>}><TestimonialBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='testimonial/edit' element={<PrivateRoute><Suspense fallback={<></>}><TestimonialBackend type='edit' /></Suspense></PrivateRoute>} />

            {/* ++++++++++++++ Blog +++++++++++++++++ */}
            <Route path='blog/list' element={<PrivateRoute><Suspense fallback={<></>}><BlogBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='blog/add' element={<PrivateRoute><Suspense fallback={<></>}><BlogBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='blog/edit/:blog_id' element={<PrivateRoute><Suspense fallback={<></>}><BlogBackend type='edit' /></Suspense></PrivateRoute>} />
            {/* ++++++++++++++ Social Media Advo +++++++++++++++++ */}
            <Route path='social-media-advo/list' element={<PrivateRoute><Suspense fallback={<></>}><SocialMediaAdvoBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='social-media-advo/add' element={<PrivateRoute><Suspense fallback={<></>}><SocialMediaAdvoBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='social-media-advo/edit' element={<PrivateRoute><Suspense fallback={<></>}><SocialMediaAdvoBackend type='edit' /></Suspense></PrivateRoute>} />
            {/* ++++++++++++++ Dashboard +++++++++++++++++ */}
            <Route path='admin/dashboard' element={<PrivateRoute><Suspense fallback={<></>}><AdminDashboardBackend /></Suspense></PrivateRoute>} />
            <Route path='admin/add-admin' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='admin' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-doctor' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='doctor' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-doctor-assistant' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='doctor-assistant' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-patient' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='patient' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-social-advocate' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='social-advocate' /></Suspense></PrivateRoute>} />
            {/* +++++++++++++++++++ Calendar Management ++++++++++++++++++++++++++++++ */}
            <Route path='calendar-management-available-slots' element={<PrivateRoute><Suspense fallback={<></>}><CalendarManagementBackend type='calendar-management-available-slots' /></Suspense></PrivateRoute>} />
            <Route path='calendar-management-create-event' element={<PrivateRoute><Suspense fallback={<></>}><CalendarManagementBackend type='calendar-management-create-event' /></Suspense></PrivateRoute>} />
            <Route path='calendar-management-edit-event' element={<PrivateRoute><Suspense fallback={<></>}><CalendarManagementBackend type='calendar-management-edit-event' /></Suspense></PrivateRoute>} />
            <Route path='calendar-management-event-listing' element={<PrivateRoute><Suspense fallback={<></>}><CalendarManagementBackend type='calendar-management-event-listing' /></Suspense></PrivateRoute>} />
            <Route path='calendar-management-booked-slots' element={<PrivateRoute><Suspense fallback={<></>}><CalendarManagementBackend type='calendar-management-booked-slots' /></Suspense></PrivateRoute>} />
            <Route path='calendar-management-book-slot' element={<PrivateRoute><Suspense fallback={<></>}><CalendarManagementBackend type='calendar-management-book-slot' /></Suspense></PrivateRoute>} />

            {/*++++++++++++++++++++++++++++++++++Backend Gallery++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
            <Route path='gallery/add/video' element={<PrivateRoute><Suspense fallback={<></>}><VideoGalleryBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='gallery/edit/video' element={<PrivateRoute><Suspense fallback={<></>}><VideoGalleryBackend type='edit' /></Suspense></PrivateRoute>} />

            <Route path='gallery/video/list' element={<PrivateRoute><Suspense fallback={<></>}><VideoGalleryBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='gallery/add/image' element={<PrivateRoute><Suspense fallback={<></>}><ImageGalleryBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='gallery/edit/image' element={<PrivateRoute><Suspense fallback={<></>}><ImageGalleryBackend type='edit' /></Suspense></PrivateRoute>} />

            <Route path='gallery/image/list' element={<PrivateRoute><Suspense fallback={<></>}><ImageGalleryBackend type='list' /></Suspense></PrivateRoute>} />

            {/*++++++++++++++++++++++++++++++++++Backend Banner++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}

            {/* <Route path='banner/list' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='banner/add' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='banner/edit' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='edit' /></Suspense></PrivateRoute>} /> */}
            <Route path='myaccount' element={<PrivateRoute><Suspense fallback={<></>}><MyAccount type='profile' /></Suspense></PrivateRoute>} />
            <Route path='change-password' element={<PrivateRoute><Suspense fallback={<></>}><MyAccount type="password" /></Suspense></PrivateRoute>} />
            <Route path="forgot-password" element={<PasswordRoute><Suspense fallback={<></>}><ForgotPassword /></Suspense></PasswordRoute>} />
            <Route path="forgot-password/:_id/:token" element={<PasswordRoute><Suspense fallback={<></>}><ForgotPasswordPage /></Suspense></PasswordRoute>} />




            {/* This route is only for testing any component-view, pest your component and test the view */}
            {/* <Route path='test' element={<Suspense fallback={<></>}><ReuseTestimonialCard /></Suspense>} /> */}


          </Route>
          {/* ----------------------------------------- No Page --------------------------------- */}
          <Route path='*' element={<Suspense Suspense fallback={<></>}> <NoPage /></Suspense>} />

          {/* ----------------------------------------- Waste Routes --------------------------------- */}
          {/* <Route path='blog-cart' element={<Suspense fallback={<></>}><BlogCard /></Suspense>} />
          <Route path='calender' element={<Suspense fallback={<></>}><Calender /></Suspense>} />
          <Route path='patients-link-block' element={<Suspense fallback={<></>}><PatientsLinkBlock /></Suspense>} />
          <Route path='review-block' element={<Suspense fallback={<></>}><ReviewBlock /></Suspense>} /> */}
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
