import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultBodyBackend } from "../../common/ReuseComponents/reqBody";
import store from "../../store";


//////////////////////////// For user list ////////////////////////
export const getdataforbloglist = createAsyncThunk(
  "/blog/list",
  async (reqBody) => {
    console.log("reqBody ===>", reqBody);
    if (reqBody == null && reqBody == undefined) reqBody = defaultBodyBackend({ source: "blog_views" });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api1/bloglisting`,
      requestOptions
    );
    console.log("Next 222 step", response);
    const res = await response.json();
    console.log("Next 222333 step", res);

    return res;
  }
);

//-----------------------------status update--------------------------//
export const blogStausUpdate = createAsyncThunk(
  "blog/list/status",
  async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(
      process.env.REACT_APP_API_URL + "api1/blogstatusupdate",
      requestOptions
    );
    const respData = await responseData.json();
    return respData;
  }
);

//----------------------------------search---------------------------------//
export const getBlogSeachList = createAsyncThunk(
  "blog/search/list",
  async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(
      process.env.REACT_APP_API_URL + "api1/bloglisting",
      requestOptions
    );
    const responseCount = await fetch(
      process.env.REACT_APP_API_URL + "api1/bloglistingcount",
      requestOptions
    );
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
  }
);

//----------------------------------count---------------------------------//
export const getBlogListCount = createAsyncThunk(
  "blog/list/count",
  async (reqBody) => {
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    console.log("process.env==>", process.env);
    const responseCount = await fetch(
      process.env.REACT_APP_API_URL + "api1/bloglistingcount",
      requestOptions
    );
    const respdata = await responseCount.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);
//---------------API CALL FOR MULTI-DELETE----------------------//
export const getBlogmultidelete = createAsyncThunk(
  "blog/multidelete",
  async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    let req_body = {};
    req_body = { _ids: reqbody, source: "blogs", email: userMail, token: accessToken };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api1/multideleteblog",
      requestOptions
    );
    const respdata = await response.json();
    return respdata;
  }
);

//---------------API CALL FOR MULTI-EDIT----------------------//
export const getBlogmultiedit = createAsyncThunk(
  "blog/multiedit",
  async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api1/multieditblog",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const getblogEditData = createAsyncThunk("myaccount", async (reqbody) => {
  console.log("getuserdetailshitttttttttttttt", reqbody);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL_NODE}api1/getblogeditdata?_id=${reqbody.searchcondition._id}&source=${reqbody.source}`,
    // requestOptions
  );
  console.log("getUserData==>", response);

  const resData = await response.json();
  console.log("getUserData==>", resData);

  return resData;
});

//--------------------------------------------------------------------//
const blogSlice = createSlice({
  name: "blogSlice",
  initialState: {
    loading: false,
    message: null,
    blogData: [],
    frontEndBlogData: [],
    frontEndBlogDetail: {
      blogDetailArr: [],
      singleBlogDetail: null
    },
    loadMore: false,
    viewLess: false,
    noMoreData: false,
    editDataForBlog: null,
    statusChangeFlag: null,
    snackbar: false,
    successmsg: "",
    success: false,
    submitted: false,
    submitting: false,
    multiDeleteFlage: null,
    multiEditFlage: null,
    blogListCount: null,
  },
  reducers: {
    setBlogEditData(state, action) {
      console.log("state from seteditdata==>", state, action);
      if (action.payload !== undefined && action.payload != null) {
        state.editDataForBlog = action.payload;
      }
    },
    clearEditData(state, action) {
      console.log("state from seteditdata==>", state, action);
      state.editDataForBlog = null;
    },
    clearBlogListing(state, action) {
      console.log('first-------->action', action);
      state.frontEndBlogData = [];
    },
    clearBlogDetails(state, action) {
      if (action.payload && (action.payload = "Frontend Details")) {
        state.frontEndBlogDetail.blogDetailArr = [];
        state.frontEndBlogDetail.singleBlogDetail = null;
      }
    },

    clearmultiDeleteFlage(state, action) {
      state.multiDeleteFlage = null;
    },

    clearstatusChangeFlag(state, action) {
      state.statusChangeFlag = null;
    },

    clearmultiEditFlage(state, action) {
      state.multiEditFlage = null;
    },



  },
  extraReducers: {
    ////////////////////////////////userlistasync for dashboard////////////////
    [getdataforbloglist.pending]: (state, action) => {
      state.loading = true;
      state.blogData = []
    },
    [getdataforbloglist.fulfilled]: (state, action) => {
      state.loading = false;
      // if (action.payload.status === "success") {
      //   if (action.payload.relation === "Frontend") {
      //     if (action.payload.reqbody.condition.skip == 0) {
      //       // console.log("Inital Page Load");
      //       if (action.payload.results.res.length < 6) {
      //         state.loadMore = false;
      //         state.viewLess = false;
      //       } else {
      //         state.loadMore = true;
      //         state.viewLess = false;
      //       }
      //     } else {
      //       if (action.payload.results.res.length < 4) {
      //         // console.log("Load More Button clicked");
      //         state.loadMore = false;
      //         // state.message = "All blogs are fetched successfully !!";
      //         if (action.payload.results.res.length == 0) {
      //           state.viewLess = false;
      //           state.message = "No more data found !!";
      //         } else {
      //           state.viewLess = true;
      //           state.message = "New data added successfully !!";
      //         }
      //       } else {
      //         state.loadMore = true;
      //         state.viewLess = false;
      //         state.message = "New data added successfully !!";
      //       }
      //     }

      //     state.frontEndBlogData = [...state.frontEndBlogData, ...action.payload.results.res];
      //   } else if (action.payload.relation === "Frontend Details") {
      //     state.frontEndBlogDetail.blogDetailArr = action.payload.results.res;
      //     state.frontEndBlogDetail.singleBlogDetail = action.payload.singleData[0] ? action.payload.singleData[0] : null;
      //   } else state.blogData = action.payload.results.res;
      //   state.submitting = false;
      // }


      if (action.payload.status === "success") {
        if (action.payload.reqbody.relation === "Frontend") state.frontEndBlogData = action.payload.results.res;
        else if (action.payload.reqbody.relation === "Frontend Details") {
          state.frontEndBlogDetail.blogDetailArr = action.payload.results.res;
          state.frontEndBlogDetail.singleBlogDetail = action.payload.singleData[0] ? action.payload.singleData[0] : null;
        } else if (action.payload.reqbody.relation == 'Backend') {state.blogData = action.payload.results.res;}
        else state.blogData = action.payload.results.res;
      }


    },
    [getdataforbloglist.rejected]: (state, action) => {
      state.loading = false;
    },

    //--------------------FOR MULTI-DELETE blog----------------------------//
    [getBlogmultidelete.pending]: (state, action) => {
      state.loading = true;
      state.multiDeleteFlage = true;
    },
    [getBlogmultidelete.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.successmsg = "Deleted Successfully";
        state.multiDeleteFlage = false;
        state.submitting = false;
      }
    },
    [getBlogmultidelete.rejected]: (state, action) => {
      state.loading = false;
      state.successmsg = "Something went wrong";
      state.multiDeleteFlage = false;
    },

    //---------------------MULTI-EDIT blog------------------------//
    [getBlogmultiedit.pending]: (state, action) => {
      state.loading = true;
      state.multiEditFlage = true;
    },
    [getBlogmultiedit.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.successmsg = "Updated Successfully";
        state.multiEditFlage = false;
        state.submitting = false;
      }
    },
    [getBlogmultiedit.rejected]: (state, action) => {
      state.loading = false;
      state.multiEditFlage = false;
      state.successmsg = "Something went wrong";
    },

    //--------------------FOR edit blog----------------------------//
    [getblogEditData.pending]: (state, action) => {
      state.loading = true;
    },
    [getblogEditData.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        state.loading = false;
        state.editDataForBlog = action.payload.results.res[0];
      }
    },
    [getblogEditData.rejected]: (state, action) => {
      state.loading = false;
      state.successmsg = "Something went wrong";
    },

    //---------------------- search --------------------------//
    [getBlogSeachList.rejected]: (state, action) => {
      state.loading = false;
    },
    [getBlogSeachList.pending]: (state, action) => {
      state.loading = true;
      state.blogData = null;
      state.message = null;
    },
    [getBlogSeachList.fulfilled]: (state, action) => {
      console.log("state", state, action);
      state.loading = false;
      if (
        action.payload.respData.status === "success" &&
        action.payload.respCount.status === "success"
      ) {
        // payload:respCount:count // respData:results:res
        console.log("state222", state, action);
        state.blogData = action.payload.respData.results.res;
        state.blogListCount = action.payload.respCount.count;
        state.message = action.payload.respData.status;
      }
    },

    //---------------------- count --------------------------//
    [getBlogListCount.rejected]: (state, action) => {
      state.loading = false;
    },
    [getBlogListCount.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlogListCount.fulfilled]: (state, action) => {
      console.log("first------>state", state, action);
      state.loading = false;
      if (action.payload.status === "success") {
        console.log("state222", state, action);
        state.blogListCount = action.payload.count;
      }
    },
    //////////////////////////////////status update///////////////////////////////////
    [blogStausUpdate.pending]: (state, action) => {
      state.loading = true;
      state.statusChangeFlag = true;
    },
    [blogStausUpdate.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status == "success") {
        state.loading = false;
        state.submitting = false;
        state.statusChangeFlag = false;
        // state.message = action.payload.message;
        // state.snackbar = true;
      }
    },
    [blogStausUpdate.rejected]: (state, action) => {
      state.loading = false;
      state.statusChangeFlag = false;
    },
  },
});

export const { setBlogEditData, clearEditData, clearBlogListing, clearBlogDetails, clearmultiDeleteFlage, clearstatusChangeFlag, clearmultiEditFlage } =
  blogSlice.actions;

export default blogSlice.reducer;
