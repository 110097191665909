import React from 'react'
import "./Xwrap.css"
 
const Xwrap = () => {
    return (
        <>
            <div className='xwrap_main_wrap'>
                <div className='xwrap_wrap'>
                    <img src="https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg2.webp" alt='' className='xwrap_img'/>
                    <div className='xwrap_content_wrap'>
                        <h3>XWARP</h3>
                        <p>XWRAP® is a regenerative,<span className='text_yellow'>anti-adhesive, anti-inflammatory,</span>  and anti-microbial amnion derived
                            biological used to repair DFUs, VLUs, and Pressure Ulcers</p>
                        <p>

                            XWRAP® is intended for, but not limited to use for chronic wound repair.
                        </p>
                        <p>
                            XWRAP® is the intermediate amniotic epithelial layer of the placenta. This layer is a rich source of
                            epidermal growth factor(EGF), fibroblast growth factor (FGF), platelet derived growth factor (PDGF), and vascular endothelial growth factor (VEGF), which stimulate angiogenesis; Tissue inhibitors of metalloproteinases (TIMPs), which inhibit metalloproteinase activity and matrix degradation;
                            Extracellular matrix proteins such as fibronectins and laminins, which drive cell attachment,
                            migration, and subsequent organization; as well as<span className='text_yellow'> Type I, II, III, V, and VI </span>collagen.

                        </p>
                        <p>
                            XWRAP® is recovered during a Cesarean deliveryfrom healthy, pre-screened donors, and is processed to preserve the structural integrity ofthe amniotic epithelial membrane. A Clinical Laboratory
                            Improvement Amendment(CLIA) certified lab that meets or exceeds the American Association of Tissue Banks (AATB) criteria screens and tests XWRAP® to minimize the risk of communicable
                            diseases. All donors’ medical history and behavior is assessed and evaluated following United States Public Health Service (USPHS) guidelines.

                        </p>
                        <p>

                            XWRAP® is an allograft tissue that is regulated under section 361 of the PHS Act and meets the
                            criteria of<span className='text_yellow'>Title 21 Code for Federal Regulation (CFR) 1271.3 and 1271.10.</span> From tissue recovery, tissue
                            processing, and packaging, Applied Biologics ensures that each unit of XWRAP® has been carefully screened and meets industry standards for the use of biologic products in urgery.
                        </p>
                        <p>
                            Applications
                        </p>
                        <p>
                            XWRAP® is an amniotic membrane that can be used to repair DFUs, VLUs and Pressure Ulcers
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Xwrap