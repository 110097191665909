import React from 'react'
import '../Meet-our-Providers/MeetOurProviders.css'
import MeetourProvidersBanner from './Component/MeetourProvidersBanner'

function MeetOurProviders() {
  return (
    <div>

      <MeetourProvidersBanner />


      <div className='meet_providers_block2'>

        <div className='common_width'>

          <div className='meet_providers_innr_block'>

              <div className='meet_pro_subblock1'>

              <div className='roundimage_imgmainwrapper'>
                          <div className='roundimage_imgsubwrapper'>
                              <div className='roundimage_imgwrapper'>
                                  <img src={`${process.env.REACT_APP_IMG_URL}meet_pro_man_image_round.webp`}/>
                              </div>
                          </div>
                </div>

                <div className='meet_pro_txt_block'>

                  <div className='textwrapper'>
                    <h2>DR. GARY SMITH</h2>
                    <p>Dr. Smith Believes healthcare should emphasize and provide true healing rather than symptom care. He also believes this care can be less invasive and less costly than care often seen today. The goal is helping the body heal itself and that we should use methods with great risk and side-effects as a last resort. Too often people are not referred to alternative healthcare.</p>
                    <p>Dr. Smith started practice in 1985. He estimates he has seen over 20,000 patients in that time. </p>

                    <h3>Some things he has done:</h3>
                  </div>

                 <div className='block_listwrapper green_bullet_point'>
                <p>Management and symptomatic relief of chronic (long-term) INTRACTABLE pain.</p>
                <p>Adjunctive treatment of post-traumatic pain syndromes.</p>
                <p>Adjunctive treatment in the management of post-surgical pain conditions.</p>
                <p>Relaxation of muscle spasms.</p>
                <p>Neuro-muscular reeducation.</p>
                <p>Prevention or retardation of disuse atrophy.</p>
                <p>Maintaining or increasing range of motion.</p>
                <p>Increasing local circulation.</p>
                <p>Immediate post-surgical stimulation of calf muscles to prevent phlebothrombosis.</p>
                
              </div>



                </div>

              </div>

              <div className='textwrapper txt_sec1'>
                <p>
                His role at Health and Pain Care LLC is to facilitate the neuron regeneration work offered through our talented onstaff Nurse Practitioner.  He provides the very effective myofascial release of deep tissue work along with extensive training and experience in joint manipulation. The combination of his methods with the nurse practitioner wellness models and programs are providing excellent benefits for our patients! 
                </p>
                <p>Through years of experience and research in current wellness medicine Dr. Smith has shown that chronic pain follows specific disease states. He has shown time and again that it is a combination of unresolved scar tissue, joint dysfunction, and pain neuro overload causing nerve degeneration. Through his wellness center Health and Pain Care, LLC he has developed a powerful 1, 2 or 3 pronged approach.  This includes joint manipulation, muscle work, and neuro regeneration. </p>

              </div>

          </div>

        </div>

      </div>


      <div className='meet_provider_block3'>
        

            <div className='meet_pro_block3_sec1'>

              <img src={`${process.env.REACT_APP_IMG_URL}meet_pro_block3_chrctr_img.webp`}/>
              <div className='common_width'>

              <div className='meet_pro_block3_txt'>
               
                <h2>NICOLE SBROCCHI</h2>
                <h3>MSN, PMHNP-BC</h3>
                <div className='textwrapper'>
                  <p>Nicole is a Board Certified Nurse Practitioner qualified to practice, diagnose, treat, and prescribe medication for children, adolescents, and adults. Some of the practice specialities include <span>(but not limited to)</span> pain and peripheral neuropathy, psychiatric disorders, sleep disorders, and ADHD.</p>
                  <p>Nicole’s way of practicing is both traditional, meaning she prescribes up-to-date psychiatric medications, and alternative medicine. Alternative medicine is natural treatments which include vitamins, supplements, lifestyle modifications, nutrition, exercise, and stress management. Nicole believes that the mind, body, and spirit are interconnected, that we are all a whole person and not just a series of parts or systems. </p>
                  <p>Nicole received her Bachelor’s degree in Nursing from Mercy College of Toledo in 2016, she then continued with her graduate studies at Walden University where she received her Masters Degree as a Nurse Practitioner in Psychiatric Mental Health. She is an ANCC Board Certified Nurse Practitioner. Her passion and experience lies in psychiatry, and she enjoys working with a variety of ages.</p>
                  <p>Nicole has expertise with many of the most advanced technologies in early disease detection and pain management. These include the Sanaxes electric cell signaling therapies and the early detection of over 20 disease states through our Health Risk Analysis. Keeping up with the latest science while keeping a personal touch and good experience for her patients is her top priority.</p>
                  <p>Nicole’s hobbies include traveling, crafting, baseball games, and enjoying time spent with her children and loved ones.  She is a real family friendly and down to earth health care professional and we welcome you to come and in and see for yourself!</p>

                </div>

              </div>
              </div>

            </div>
            <div className='meet_pro_block3_innr2'>
            <div className='common_width'>
            <div className='txt_sec'>
              <h5>“Give, and it will be given to you. A good measure, pressed down, shaken together and running over, will be poured into your lap. For with the measure you use, it will be measured to you.” (Luke 6:38)
              </h5>
               <p>It is in giving that we truly receive.</p>
            </div>
            </div>

        </div>
        
      </div>    
        {/* <div className='banner_meetourproviders'>
            <img src='https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/health+and+pain+care-Meet+our+providers-Banner.webp'/>
        </div>
        <div className='banner_meetourproviders'>
            <img src='https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/health+and+pain+care-Meet+our+providers-page-block1.webp'/>
        </div>
        <div className='banner_meetourproviders'>
            <img src='https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/health+and+pain+care-Meet+our+providers-page_block3.webp'/>
        </div> */}
      
    </div>
  )
}

export default MeetOurProviders
