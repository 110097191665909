import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const login = createAsyncThunk('user/login', async (body) => {
  console.log('body====>', body)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body)
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}userlogin`, requestOptions);
  const res = await response.json();
  return res;
})
export const getipInfo = createAsyncThunk("user/getipInfo", async () => {
  console.log("process.env==>", process.env);

  const ipresponse = await fetch(process.env.REACT_APP_API_URL_IP);
  const ipInfo = await ipresponse.json();
  console.log('ipInfo==>', ipInfo);
  // console.log('ipresponse==>', ipresponse);
  ipInfo.browser = getbrowserinfo();
  return ipInfo;
});

//////////////////////////// For user list ////////////////////////
export const getdataforusernext = createAsyncThunk(
  "dashboard/next",
  async (reqBody) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api1/listingusers`,
      requestOptions
    );
    console.log("Next 222 step", response)
    const res = await response.json();

    return res;
  }
);

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState: {
    ipInfo: {},
    errorMessage: null,
    userInfo: {},
    token: null,
    userType: null,
    last_login_time: null,
    loading: false,
    message: null,
    isLoggedIn: null,
    userlistasyncdata: {},
    loading: false,
    success: {},
  },
  reducers: {
    clearMessageIsLoggedIn(state, action) {
      state.message = null;
      state.isLoggedIn = null;
    },
    clearMessageAfterLoggedIn(state, action) {
      state.message = null;
    },
    setloginError(state, action) {
      state.errorMessage = null
    },
    setLogedinUserInfo(state, action) {
      console.log("state from setLogedinUserInfo==>", state);
      console.log("action.payload from setLogedinUserInfo==>", state, action.payload);
      if (action.payload !== undefined && action.payload.token != null && action.payload.userInfo != null) {
        state.userInfo = action.payload.userInfo;
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.userType = action.payload.userInfo.type;
        state.last_login_time = action.payload.lastLoginTime;
      }
      console.log("state.userInfo setLogedinUserInfo==>", state.userInfo);
    },
    logOut(state, action) {
      console.log("state from logOut==>", state);
      console.log("payload from logOut==>", action);
      state.userInfo = {};
      state.token = null;
      state.last_login_time = null;
      state.isLoggedIn = null;
      state.errorMessage = null;
      console.log("state from logOut after set==>", state);
    },
    setUserInitialInfo(state, action) {
      console.log()
    }
  },
  extraReducers: {
    [getipInfo.fulfilled]: (state, action) => {
      console.log("action of getipInfo.fulfilled==>", action);
      state.ipInfo = action.payload;
      console.log("state of getipInfo.fulfilled==>", state.ipInfo);
    },
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status == 'success') {
        state.token = action.payload.token;
        state.userInfo = action.payload.item;
        state.userType = action.payload.item.type;
        state.isLoggedIn = true;
        state.last_login_time = action.payload.last_login_time
        state.message = action.payload.message ? action.payload.message : 'Login Successful';
      }
      if (action.payload.status == 'error') {
        state.isLoggedIn = false;
        state.message = action.payload.message ? action.payload.message : 'Something Went Wrong!';
      }
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
    },

    ////////////////////////////////userlistasync for dashboard////////////////
    [getdataforusernext.pending]: (state, action) => {
      state.loading = true;
    },
    [getdataforusernext.fulfilled]: (state, action) => {
      console.log("action", action);
      if (action.payload.status === "success") {
        state.userlistasyncdata = action.payload.results.res;
        state.loading = false;
        state.submitting = false;

      }
    },
    [getdataforusernext.rejected]: (state, action) => {
      state.loading = false;
    },
  }
})

export const { clearMessageIsLoggedIn, logOut, setLogedinUserInfo,clearMessageAfterLoggedIn } = loginSlice.actions;

export default loginSlice.reducer;

function getbrowserinfo() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return ('Opera');
  }
  else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return ('Edge');
  }
  else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return ('Chrome');
  }
  else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return ('Safari');
  }
  else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return ('Firefox');
  }
  else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
  {
    return ('IE');
  }
  else {
    return ('unknown');
  }
}